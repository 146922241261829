<template>
	<div class="parent">

		<!-- Loading overlay screen-->
		<b-overlay :show="showOverlay" rounded="sm">

			<!--Confirm send Notification-->
			<b-modal id="confirmSubmit" centered title="Confirmation" @ok="sendSMS()">

				<p v-if="sms_time == `scheduled`">
					Before clicking OK, please confirm the information before sending. This SMS will be scheduled to be sent to:
				</p>
				<p v-else>
					Before clicking OK, please confirm the information before sending. This SMS will be sent to:
				</p>
				<p v-if="form.customPhoneNumbers">{{ form.customPhoneNumbers.split(',').length }} custom phone numbers</p>
				<p v-if="form.sendToMasters">{{ form.master.length }} masters</p>
				<p v-if="form.sendToRetailers">{{ form.retailer.length }} retailers</p>
				<p v-if="form.sendToAgents">{{ form.agent.length }} agents</p>
			</b-modal>

			<!--Process in background message-->
			<b-modal id="processInBackgroundMessage" ok-only title="Confirmation">
				<div>
					This is a high volume request. SMS's are being sent in the background, and it may take several minutes. You
					may close this window.
				</div>
			</b-modal>

			<!-- Navbar -->
			<navbar/>

			<!--Container for 2 column grid on page-->
			<div class="container">
				<div class="border-bottom titleWrapper mt-4">
					<h2>Send Internal SMS</h2>
				</div>

				<b-form
					action="#"
					@submit.prevent=""
					novalidate
					enctype="multipart/form-data">

					<div v-if="1 == 0" class="mt-5" style="font-size: 18px">
						This functionality is under maintenance.
					</div>
					<div v-else>
						<!-- Masters row -->
						<div class="row mt-4 text-left">
							<div class="col">
								<strong class="d-block w-100 mb-1">Masters</strong>
							</div>
						</div>
						<div class="row text-left">
							<div class="col-6">
								<!--Masters-->
								<b-form-group label="Status" label-class="mb-0" label-for="mastersStatus" label-size="sm" class="mt-0">
									<multiselect
										id="mastersStatus"
										v-model="form.master_status"
										track-by=""
										placeholder="-- Please select an option --"
										label=""
										:options="statusesA"
										:multiple="true"
										:close-on-select="false"
										:clear-on-select="false"
										:preserve-search="true"
										:value="statusesA"
										:groupSelect="true"
										group-values="statusesA"
										group-label="select_all">
									</multiselect>
								</b-form-group>
							</div>
							<div class="col-6">
								<b-form-group label="Master" label-class="mb-0" label-for="master" label-size="sm" class="mt-0" :class="{ 'form-group--error': $v.form.master.required }">
									<multiselect
										id="master"
										v-model="form.master"
										track-by="fullname"
										placeholder="-- Please select an option --"
										label="fullname"
										:options="masters"
										:multiple="true"
										:close-on-select="false"
										:clear-on-select="false"
										:preserve-search="true"
										:value="2"
										:groupSelect="true"
										:state="validateState('master')"
										@remove="removedMaster"
										group-values="masters"
										group-label="select_all">
									</multiselect>
									<p class="errors">
										<span v-if="$v.form.master.$error" class="text-danger error-multiselect">
											<span v-if="!$v.form.master.required" class="text-danger error-multiselect">
												Please select a master
											</span>
										</span>
									</p>
								</b-form-group>
							</div>
						</div>

						<!-- Retailers row -->
						<div class="row mt-4 text-left">
							<div class="col">
								<strong class="d-block mb-1">Retailers</strong>
							</div>
						</div>
						<div class="row text-left">
							<div class="col-6">
								<b-form-group label="Status" label-class="mb-0" label-for="retailersStatus" label-size="sm" class="mt-0">
									<multiselect
										id="retailersStatus"
										v-model="form.retailer_status"
										track-by=""
										placeholder="-- Please select an option --"
										label=""
										:options="statusesA"
										:multiple="true"
										:close-on-select="false"
										:clear-on-select="false"
										:preserve-search="true"
										:value="statusesA"
										:groupSelect="true"
										:disabled="isDisabled_retailers"
										group-values="statusesA"
										group-label="select_all">
									</multiselect>
								</b-form-group>
							</div>
							<div class="col-6">
								<b-form-group label="Retailer" label-class="mb-0" label-for="retailer" label-size="sm" class="mt-0">
									<multiselect
										id="retailer"
										v-model="form.retailer"
										track-by="fullname"
										placeholder="-- Please select an option --"
										label="fullname"
										:options="retailers"
										:multiple="true"
										:close-on-select="false"
										:clear-on-select="false"
										:preserve-search="true"
										:value="retailers.r_id"
										:groupSelect="true"
										:disabled="isDisabled_retailers"
										@remove="removedRetailer"
										group-values="retailers"
										group-label="select_all">
									</multiselect>
									<p class="errors">
										<span v-if="$v.form.retailer.$error" class="text-danger error-multiselect">
											<span v-if="!$v.form.retailer.required" class="text-danger error-multiselect">
												Please select a retailer
											</span>
										</span>
									</p>
								</b-form-group>
							</div>
						</div>

						<!-- Agents row -->
						<div class="row mt-4 text-left">
							<div class="col">
								<strong class="d-block mb-1">Agents</strong>
							</div>
						</div>
						<div class="row text-left">
							<div class="col-6">
								<b-form-group label="Status" label-class="mb-0" label-for="agentsStatus" label-size="sm" class="mt-0">
									<multiselect
										id="agentsStatus"
										v-model="form.agent_status"
										track-by=""
										placeholder="-- Please select an option --"
										label=""
										:options="statusesB"
										:multiple="true"
										:close-on-select="false"
										:clear-on-select="false"
										:preserve-search="true"
										:value="statusesA"
										:groupSelect="true"
										:disabled="isDisabled_agents"
										group-values="statusesB"
										group-label="select_all">
									</multiselect>
								</b-form-group>
							</div>
							<div class="col-6">
								<b-form-group label="Agent" label-class="mb-0" label-for="agent" label-size="sm" class="mt-0">
									<multiselect
										id="agent"
										v-model="form.agent"
										track-by="fullname"
										placeholder="-- Please select an option --"
										label="fullname"
										:options="agents"
										:multiple="true"
										:close-on-select="false"
										:clear-on-select="false"
										:preserve-search="true"
										:value="agents.e_id"
										:groupSelect="true"
										:disabled="isDisabled_agents"
										group-values="agents"
										group-label="select_all">
									</multiselect>
									<p class="errors">
										<span
											v-if="$v.form.agent.$error"
											class="text-danger error-multiselect">
											<span
												v-if="!$v.form.agent.required"
												class="text-danger error-multiselect">
													Please select an agent
											</span>
										</span>
									</p>
								</b-form-group>
							</div>
						</div>

						<!-- Rest of fields -->
						<div class="row mt-1 text-left">
							<div class="col-6">
								<!--Phone Numbers Input-->
								<b-form-group
									label="Custom Phone Numbers"
									label-class="mb-0"
									label-for="phoneNumbers"
									label-size="sm"
									class="mt-4">
									<b-form-textarea
										id="phoneNumbers"
										v-model="$v.form.customPhoneNumbers.$model"
										:state="validateState('customPhoneNumbers')"
										placeholder="Custom Phone Numbers..."
										rows="3"
										max-rows="12"
										size="sm">
									</b-form-textarea>
									<b-form-invalid-feedback v-if="!$v.form.customPhoneNumbers.valid">
										Please enter a list of 10 digit phone numbers separated by a comma
									</b-form-invalid-feedback>
								</b-form-group>

								<!-- Title -->
								<b-form-group
									label="Title"
									label-class="mb-0"
									label-for="title"
									label-size="sm"
									class="mt-4">
									<b-form-input
										id="title"
										name="title"
										size="sm"
										v-model="$v.form.title.$model"
										:state="validateState('title')"
										placeholder="Title..." />
									<b-form-invalid-feedback>
										Title is required
									</b-form-invalid-feedback>
								</b-form-group>

								<!-- Message -->
								<b-form-group
									label="Message"
									label-class="mb-0"
									label-for="message"
									label-size="sm"
									class="mt-4">
									<b-form-textarea
										id="message"
										v-model="$v.form.message.$model"
										:state="validateState('message')"
										placeholder="Message..."
										rows="3"
										max-rows="12"
										size="sm">
									</b-form-textarea>
									<div class="limiter mt-2"><span>{{ charactersCount }}</span></div>
									<b-form-invalid-feedback v-if="!$v.form.message.required">
										Please enter a message
									</b-form-invalid-feedback>
									<b-form-invalid-feedback v-if="!$v.form.message.maxLength">
										Message must be 160 characters maximum.
									</b-form-invalid-feedback>
								</b-form-group>

								<b-form-checkbox
									id="checkbox-sendToMasters"
									class="mt-4"
									v-model="form.sendToMasters"
									v-bind:value="true"
									name="checkbox-sendToMasters"
									:state="validateState('sendToMasters')"
									size="sm">
									Send SMS to masters selected
								</b-form-checkbox>

								<b-form-checkbox
									id="checkbox-sendToRetailers"
									class="mt-2"
									v-model="form.sendToRetailers"
									name="checkbox-sendToRetailers"
									size="sm"
									v-bind:value="true"
									:disabled="isDisabled_retailers"
									:state="validateState('sendToRetailers')">
									Send SMS to retailers selected
								</b-form-checkbox>

								<b-form-checkbox
									id="checkbox-sendToAgents"
									class="mt-2"
									v-model="form.sendToAgents"
									name="checkbox-sendToAgents"
									size="sm"
									v-bind:value="true"
									:disabled="isDisabled_agents"
									:state="validateState('sendToAgents')">
									Send SMS to agents selected
								</b-form-checkbox>

							</div>

							<div class="col-6">
								<!-- SMS time -->
								<b-form-group label="SMS Time" label-size="sm" class="mt-3">
									<b-form-radio-group
										v-model="sms_time"
										:options="sms_times"
										size="sm"></b-form-radio-group>
								</b-form-group>
								<b-form-group
									v-if="sms_time === 'scheduled'"
									label="Scheduled days"
									label-class="mt-0"
									label-for="scheduledDays"
									label-size="sm">
									<b-form-input
										id="scheduledDays"
										name="scheduledDays"
										size="sm"
										v-model="$v.form.scheduledDays.$model"
										:state="validateState('scheduledDays')"
										placeholder="Scheduled days..."
										number />

									<div class="row mt-3">
										<div class="col pr-0">
											<b-form-select v-model="time_selected" :options="time_options" class="time_picker">
											</b-form-select>
										</div>
										<div class="col">
											<b-form-select v-model="am_pm_selected" :options="am_pm_options" class="time_picker">
											</b-form-select>
										</div>
									</div>
								</b-form-group>
							</div>
						</div>

						<!-- Apply/Submit buttons-->
						<div class="row mt-4 pb-5 text-right">
							<div class="col">
								<button type="submit" class="btn button1 btn-sm" @click="submitAlert()">
									Submit
								</button>
							</div>
						</div>

					</div>

				</b-form>

			</div>
		</b-overlay>
	</div>
</template>
<script>
import Navbar from "@/components/common/Navbar"
import { validationMixin } from "vuelidate"
import Multiselect from "vue-multiselect"

// register globally
// Vue.component('multiselect', Multiselect)
import {
	required,
	requiredIf,
	maxLength,
	helpers,
	sameAs, integer, minValue,
} from "vuelidate/lib/validators"

export default {
	mixins: [validationMixin],

	name: "SMScenter",
	components: {
		Navbar,
		Multiselect
	},

	computed: {
		charactersCount() {
			if (this.form.message) {
				var char = this.form.message.length
				return char + " characters used"
			}
		}
	},

	data() {
		return {
			/**
			 * Flags
			 */
			showOverlay: false,
			isDisabled_retailers: true,
			isDisabled_agents: true,

			statusesA: [
				{
					select_all: "Select All",
					statusesA: [
						"Active",
						"Inactive"
					]
				}
			],
			statusesB: [
				{
					select_all: "Select All",
					statusesB: [
						"Active",
						"Inactive",
						"Suspend"
					]
				}
			],

			masters: [
				{
					select_all: "Select All",
					masters: []
				}
			],

			masters_id: [],

			retailers: [
				{
					select_all: "Select All",
					retailers: []
				}
			],
			retailers_id: [],

			agents: [
				{
					select_all: "Select All",
					agents: []
				}
			],
			agents_id: [],

			form: {
				master_status: null,
				master: null,
				sendToMasters: false,
				retailer_status: null,
				retailer: null,
				sendToRetailers: false,
				agent_status: null,
				agent: null,
				sendToAgents: false,
				customPhoneNumbers: null,
				title: null,
				message: null,
				scheduledDays: 1
			},

			sms_time: `oneTime`,

			sms_times: [
				{ text: `One time`, value: `oneTime` },
				{ text: `Scheduled`, value: `scheduled` }
			],
			time_selected: `01`,
			time_options: [
				{ value: "01", text: "01" },
				{ value: "02", text: "02" },
				{ value: "03", text: "03" },
				{ value: "04", text: "04" },
				{ value: "05", text: "05" },
				{ value: "06", text: "06" },
				{ value: "07", text: "07" },
				{ value: "08", text: "08" },
				{ value: "09", text: "09" },
				{ value: "10", text: "10" },
				{ value: "11", text: "11" },
				{ value: "12", text: "12" }
			],
			am_pm_selected: `pm`,
			am_pm_options: [
				{ value: "am", text: "AM" },
				{ value: "pm", text: "PM" }
			]
		}
	},

	validations() {
		const customPhoneList = helpers.regex('customPhoneList', /^(\d{10}|,)+$/);
		const shouldNotStartWithComma = value => { return !/^,+/.test(value) }
		const shouldNotEndWithComma = value => { return !/,+$/.test(value) }

		if (this.form.sendToMasters == false && this.form.sendToRetailers == false && this.form.sendToAgents == false) {
			return {
				form: {
					title: {
						required
					},
					message: {
						required,
						maxLength: maxLength(160)
					},
					scheduledDays: {
						required,
						integer,
						minValue: minValue(1)
					},
					customPhoneNumbers: {
						shouldNotStartWithComma,
						valid: customPhoneList,
						shouldNotEndWithComma
					},
					master: { required },
					retailer: {
						required: requiredIf(function () {
							return this.form.sendToRetailers
						})
					},
					agent: {
						required: requiredIf(function () {
							return this.form.sendToAgents
						})
					},
					sendToMasters: {
						sameAs: sameAs(() => {
							if (this.form.sendToRetailers == false && this.form.sendToAgents == false) {
								return true
							}
						}),
					},
					sendToRetailers: {
						sameAs: sameAs(() => {
							if (this.form.sendToMasters == false && this.form.sendToAgents == false) {
								return true
							}
						}),
					},
					sendToAgents: {
						sameAs: sameAs(() => {
							if (this.form.sendToRetailers == false && this.form.sendToMasters == false) {
								return true
							}
						}),
					}
				},
			}
		} else {
			return {
				form: {
					title: {
						required
					},
					message: {
						required,
						maxLength: maxLength(160)
					},
					scheduledDays: {
						required,
						integer,
						minValue: minValue(1)
					},
					customPhoneNumbers: {
						valid: customPhoneList,
					},
					master: { required },
					retailer: {
						required: requiredIf(function () {
							return this.form.sendToRetailers
						})
					},
					agent: {
						required: requiredIf(function () {
							return this.form.sendToAgents
						})
					},
					sendToMasters: {
						required: false
					},
					sendToRetailers: {
						required: false
					},
					sendToAgents: {
						required: false
					}
				},
			}
		}
	},

	watch: {
		"form.master_status": {
			handler: function (val) {

				if (val != null && val.length != 0) {
					let statusOptions = String(val)
					this.getMasters(statusOptions)
				} else {
					this.masters[0].masters = []
					this.form.master = []
				}
			},
			deep: true, //for nested data
		},
		"form.master": {
			handler: function (val) {
				this.masters_id = []

				if (val != null && val.length != 0) {
					for (const master of val) {
						this.masters_id.push(master.m_id)
					}
					this.isDisabled_retailers = false
				} else {
					this.isDisabled_retailers = true
					this.retailers[0].retailers = []
					this.form.retailer = []
					this.form.retailer_status = null
					this.form.sendToRetailers = false
				}
			},
			deep: true, //for nested data
		},
		masters_id: {
			handler: function (val) {
				if (val != null && val.length != 0 && this.form.retailer_status?.length > 0) {
					let options = {
						statuses: String(this.form.retailer_status),
						masters: String(this.masters_id)
					}

					this.getRetailers(options)
				}
			},
			deep: true, //for nested data
		},
		"form.retailer_status": {
			handler: function (val) {
				if (val != null && val.length != 0) {
					let options = {
						statuses: String(val),
						masters: String(this.masters_id)
					}
					this.getRetailers(options)
				} else {
					this.retailers[0].retailers = []
					this.form.retailer = []
					this.form.sendToRetailers = false
				}
			},
			deep: true, //for nested data
		},
		"form.retailer": {
			handler: function (val) {
				this.retailers_id = []

				if (val != null && val.length != 0) {
					for (const retailer of val) {
						this.retailers_id.push(retailer.r_id)
					}
					this.isDisabled_agents = false
				} else {
					this.isDisabled_agents = true
					this.agents[0].agents = []
					this.form.agent = []
					this.form.agent_status = null
					this.form.sendToAgents = false
				}
			},
			deep: true, //for nested data
		},
		retailers_id: {
			handler: function (val) {

				if (val != null && val.length != 0 && this.form.agent_status?.length > 0) {
					let options = {
						statuses: String(this.form.agent_status),
						retailers: String(this.retailers_id)
					}
					this.getAgents(options)
				}
			},
			deep: true, //for nested data
		},
		"form.agent_status": {
			handler: function (val) {
				if (val != null && val.length != 0) {
					let options = {
						statuses: String(val),
						retailers: String(this.retailers_id)
					}
					this.getAgents(options)
				} else {
					this.agents[0].agents = []
					this.form.agent = []
					this.form.sendToAgents = false
				}
			},
			deep: true, //for nested data
		},
		"form.agent": {
			handler: function (val) {
				this.agents_id = []

				if (val != null && val.length != 0) {
					for (const agent of val) {
						this.agents_id.push(agent.e_id)
					}
				} else {
				}
			},
			deep: true, //for nested data
		},
	},

	methods: {
		validateState(name) {
			const { $dirty, $error } = this.$v.form[name]

			return $dirty ? !$error : null
		},
		getMasters(incomingOptions) {
			this.axios
				.get(`/dashboard/masters?status=${incomingOptions}`)
				.then((res) => {
					const temp = res.data.data.map((m) => {
						return { ...m, fullname: `${m.fname} ${m.lname}` }
					})
					this.masters[0].masters = temp
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred"
					this.showError(message)
					console.error(error)
				})
		},
		getRetailers(incomingOptions) {
			this.axios
				.get(`/dashboard/retailers?masters=${incomingOptions.masters}&status=${incomingOptions.statuses}`)
				.then((res) => {
					const temp = res.data.data.map((m) => {
						return { ...m, fullname: `${m.fname} ${m.lname}` }
					})
					this.retailers[0].retailers = temp
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred"
					this.showError(message)
					console.error(error)
				})
		},
		getAgents(incomingOptions) {
			this.axios
				.get(`/dashboard/agents?retailers=${incomingOptions.retailers}&status=${incomingOptions.statuses}`)
				.then((res) => {
					const temp = res.data.data.map((m) => {
						return { ...m, fullname: `${m.fname} ${m.lname}` }
					})
					this.agents[0].agents = temp
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred"
					this.showError(message)
					console.error(error)
				})
		},
		submitAlert() {
			this.$v.form.$touch()

			if (this.$v.form.$anyError) {
				return
			}
			this.$bvModal.show("confirmSubmit")
		},
		sendSMS() {
			this.showOverlay = true

			let url = `/dashboard/internal-sms`
			let body2send = {
				masterId: this.form.master.map((m) => m.m_id).join(","),
				...(this.form.retailer != null && this.form.retailer.length? {retailerId: this.form.retailer.map((m) => m.r_id).join(",")}: {}),
				...(this.form.agent != null && this.form.agent.length? {agentId: this.form.agent.map((m) => m.e_id).join(",")}: {}),
				sendToMasters: this.form.sendToMasters,
				sendToRetailers: this.form.sendToRetailers,
				sendToAgents: this.form.sendToAgents,
				title: this.form.title,
				body: this.form.message
			}

			if(this.form.customPhoneNumbers)
				body2send.customPhoneNumbers = this.form.customPhoneNumbers

			if(this.sms_time == `scheduled`) {
				url = `/dashboard/internal-sms/scheduled`

				if(this.form.scheduledDays)
					body2send.scheduledDays = this.form.scheduledDays

				if(this.time_selected) {
					let timeSelected = parseInt(this.time_selected)

					if(this.am_pm_selected == `am` && timeSelected == 12)
						timeSelected = 0

					if(this.am_pm_selected == `pm`) {
						timeSelected += 12
						if(timeSelected == 24)
							timeSelected = 12
					}

					body2send.scheduledTime = timeSelected
				}
			}


			this.axios
				.post(url, body2send)
				.then((res) => {
					if(res.data.data.willProcessInBackground)
						this.$bvModal.show("processInBackgroundMessage")
					else {
						if(this.sms_time == `scheduled`) {
							let scheduledDays = this.form.scheduledDays
							let scheduledDaysStr = scheduledDays == 1 ? `${scheduledDays} day` : `${scheduledDays} days`

							this.showSuccess(`Success`, `Notification is scheduled to be sent every ${scheduledDaysStr}, starting on ${res.data.data.willRunOn}`)
						}else {
							this.showSuccess("Success", `SMS sent successfully`)
						}
					}
				})
				.catch((error) => {
					const message =
						error?.res?.data?.msg ?? "An unexpected error occurred"
					this.showError(message)
					console.error(error)
				}).finally(() => {
				this.showOverlay = false
			})
		},
		cleanAfterSubmit() {
			this.form.master_status = null
			this.form.master = null
			this.form.sendToMasters = false
			this.form.retailer_status = null
			this.form.retailer = null
			this.form.sendToRetailers = false
			this.form.agent_status = null
			this.form.agent = null
			this.form.sendToAgents = false
			this.form.message = null
		},
		removedMaster(removedOption) {
			if(this.form.retailer && this.form.retailer.length) {
				this.form.retailer = this.form.retailer.filter(function(obj) {
					return obj.m_id !== removedOption.m_id;
				});
			}
			if(this.form.agent && this.form.agent.length) {
				this.form.agent = this.form.agent.filter(function(obj) {
					return obj.m_id !== removedOption.m_id;
				});
			}
		},
		removedRetailer(removedOption) {
			if(this.form.agent && this.form.agent.length) {
				this.form.agent = this.form.agent.filter(function(obj) {
					return obj.r_id !== removedOption.r_id;
				});
			}
		},
	}
}
</script>
<style scoped lang="scss">
.parent {
	width: 100%;
	height: 100%;
	background: #fff;
}

.titleWrapper {
	display: flex;
	gap: 1em;
	padding-bottom: 0.5em;
	justify-content: space-between;
}

.limiter {
	text-align: right;
	font-size: 0.875rem;
}

.error-multiselect {
	font-size: 90%;
}
</style>