<template>
	<div class="parent">
		<!-- Navbar-->
		<navbar />

		<!-- Container -->
		<div class="container mt-4 pb-4">
			<div class="mt-4 border-bottom dashboardWrapper">
				<h3>Dashboard</h3>
				<b-form-radio-group
					v-model="optionSelected"
					:options="radioOptions"
					button-variant="outline-primary"
					buttons>
				</b-form-radio-group>
			</div>
			<div class="mt-4">
				<!-- Searchbar -->
				<div class="searchbarWrapper">
					<b-input-group size="sm" v-if="optionSelected === 'oneTime'">
						<b-input-group-prepend is-text>
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>
						<b-form-input
							type="search"
							placeholder="Search ..."
							v-model="searchQuery"
						></b-form-input>
					</b-input-group>
					<b-pagination
						:style="'margin-bottom:0'"
						v-model="currentPage"
						limit="3"
						:total-rows="currentRows"
						:per-page="perPage"
						aria-controls="dashboardTable"
					>
					</b-pagination>
				</div>

				<!-- Table -->
				<b-table
					id="dashboardTable"
					ref="dashboardTable"
					:items="notifications"
					:fields="oneTimeFields"
					:busy="isTableBusy"
					:tbody-tr-class="rowClass"
					v-model="currentItems"
					:per-page="perPage"
					responsive
					borderless
					medium
					v-if="optionSelected === 'oneTime'"
				>
					<!-- Icon next to username-->
					<template v-slot:[`cell(created_by.username)`]="row">
						<b-icon icon="person-circle"></b-icon>
						<span class="ml-1">{{ row.item.created_by.username }}</span>
					</template>

					<!-- Status (progress) -->
					<template v-slot:cell(is_in_progress)="row">
						<span v-if="row.item.is_in_progress" class="status-in-progress">
							In progress
						</span>
						<span v-else class="status-done"> Done </span>
					</template>

					<!-- Export to CSV buttons -->
					<template v-slot:cell(csv)="row">
						<b-button
							size="sm"
							class="csv-btn shadow-none"
							variant="primary"
							@click="exportCsv(row.index)"
							:disabled="row.item.isExportLoading || row.item.is_in_progress"
						>
							<b-spinner small v-if="row.item.isExportLoading" />
							<b-icon
								v-if="!row.item.isExportLoading"
								icon="file-earmark-spreadsheet-fill"
								font-scale="1"
								aria-label="Export CSV"
							></b-icon>
						</b-button>
					</template>

					<!-- Show details Icon -->
					<template v-slot:cell(dropdown)="{ detailsShowing, item }">
						<b-button
							size="sm"
							class="csv-btn shadow-none"
							variant="primary"
							@click="toggleDetails(item)"
						>
							<b-icon
								:icon="detailsShowing ? 'chevron-up' : 'chevron-down'"
								:style="detailsShowing ? 'color: #0094F8' : 'color: #BDBDBD'"
							></b-icon>
						</b-button>
					</template>

					<!-- Notification Details -->
					<template v-slot:row-details="{ item }">
						<div class="container text-left notifDetails">
							<b-row>
								<div class="col-4 my-3 border-right">
									<!-- Title -->
									<div class="grid-example">
										<div class="row-1">
											<p>Title:</p>
										</div>

										<div class="row-2">
											<p>{{ item.title }}</p>
										</div>
									</div>

									<!-- Activation Date From-->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Activation Date (From): </span>
										</div>

										<div class="row-2">
											<span>{{
												item.activation_date_from
													? new Date(item.activation_date_from).toDateString()
													: "-"
											}}</span>
										</div>
									</div>

									<!-- Activation Date To-->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Activation Date (To): </span>
										</div>

										<div class="row-2">
											<span>{{
												item.activation_date_to
													? new Date(item.activation_date_to).toDateString()
													: "-"
											}}</span>
										</div>
									</div>

									<!-- Deactivation Date From-->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Deactivation Date (From): </span>
										</div>

										<div class="row-2">
											<span>
												{{
													item.deactivation_date_from
														? new Date(
																item.deactivation_date_from
														  ).toDateString()
														: "-"
												}}</span
											>
										</div>
									</div>

									<!-- Deactivation Date To-->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Deactivation Date (To): </span>
										</div>

										<div class="row-2">
											<span>{{
												item.deactivation_date_to
													? new Date(item.deactivation_date_to).toDateString()
													: "-"
											}}</span>
										</div>
									</div>

									<!-- State -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>State(s): </span>
										</div>

										<div class="row-2">
											<span>{{
												item.state ? item.state.join(", ") : "-"
											}}</span>
										</div>
									</div>

									<!-- Status -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Status: </span>
										</div>

										<div class="row-2">
											<span>{{
												item.status ? item.status.join(", ") : "-"
											}}</span>
										</div>
									</div>

									<!-- Master -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Master: </span>
										</div>

										<div class="row-2">
											<span>{{
												item.masters ? item.masters.join(", ") : "-"
											}}</span>
										</div>
									</div>

									<!-- Retailer -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Retailer: </span>
										</div>

										<div class="row-2">
											<span>{{
												item.retailers ? item.retailers.join(", ") : "-"
											}}</span>
										</div>
									</div>

									<!-- Non-usage days range -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Non-usage days range:</span>
										</div>

										<div class="row-2">
											<span>{{ item.non_usage_days_start }} - {{ item.non_usage_days_end }}</span>
										</div>
									</div>

									<!-- App -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>App: </span>
										</div>

										<div class="row-2">
											<span>{{ item.is_app ? "Yes" : "No" }}</span>
										</div>
									</div>

									<!-- SMS -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>SMS: </span>
										</div>

										<div class="row-2">
											<span>{{ item.is_sms ? "Yes" : "No" }}</span>
										</div>
									</div>

									<!-- Customers Found -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Customers Found: </span>
										</div>

										<div class="row-2">
											<span>{{ item.customers_found }}</span>
										</div>
									</div>

									<!-- Attempted to Send Notification -->
									<div class="grid-example mb" v-if="item.is_app">
										<div class="row-1">
											<span>Attempted to Send Notification: </span>
										</div>

										<div class="row-2">
											<span>{{ item.attempted_to_send }}</span>
										</div>
									</div>

									<!-- Notification success/failure -->
									<div class="grid-example mb" v-if="item.is_app">
										<div class="row-1">
											<span>Notification success/failure: </span>
										</div>

										<div class="row-2">
											<span>{{ item.success }} / </span
											><span>{{ item.failed }}</span>
										</div>
									</div>

									<!-- Attempted to send SMS: -->
									<div class="grid-example mb" v-if="item.is_sms">
										<div class="row-1">
											<span>Attempted to send SMS: </span>
										</div>

										<div class="row-2">
											<span>{{ item.attempted_to_send_sms }}</span>
										</div>
									</div>

									<!-- Attempted to send SMS: -->
									<div class="grid-example mb" v-if="item.is_sms">
										<div class="row-1">
											<span>SMS success/failure: </span>
										</div>

										<div class="row-2">
											<span>{{ item.success_sms }} / </span
											><span>{{ item.failed_sms }}</span>
										</div>
									</div>

								</div>
								<div class="col-8 my-3">
									<b-row>
										<b-col cols="2">
											<p>Message:</p>
										</b-col>
										<b-col cols="10">
											<p>{{ item.body ? item.body : "-" }}</p>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="2">
											<p v-if="item.image_url">Image:</p>
										</b-col>
										<b-col cols="10">
											<b-img
												v-if="item.image_url"
												:src="item.image_url"
												fluid
												height="200px"
												width="200px"
											></b-img>
										</b-col>
									</b-row>
								</div>
							</b-row>
						</div>
					</template>

					<!-- Loader -->
					<template #table-busy>
						<div class="text-center mt-3">
							<b-spinner small></b-spinner>
							<strong class="ml-3">Please wait...</strong>
						</div>
					</template>
				</b-table>
				<b-table
					id="dashboardTable"
					ref="dashboardTable"
					:items="notifications"
					:fields="scheduledFields"
					:busy="isTableBusy"
					:tbody-tr-class="rowClass"
					v-model="currentItems"
					:per-page="perPage"
					responsive
					borderless
					medium
					v-if="optionSelected === 'scheduled'"
				>
					<!-- Icon next to username-->
					<template v-slot:[`cell(created_by.username)`]="row">
						<b-icon icon="person-circle"></b-icon>
						<span class="ml-1">{{ row.item.created_by.username }}</span>
					</template>

					<!-- Frequency  -->
					<template v-slot:cell(scheduled_days)="row">
						<span>
							Every
							{{ row.item.scheduled_days === 1 ? "day" : `${row.item.scheduled_days} days` }}
							at {{ row.item.scheduled_time_pst }}:00 PST
						</span>
					</template>

					<!-- Scheduled Last Run  -->
					<template v-slot:cell(scheduled_last_run)="row">
						<span v-if="row.item.scheduled_last_run">{{
							row.item.scheduled_last_run
						}}</span>
						<span v-else>Hasn't run yet</span>
					</template>

					<!-- Status (progress) -->
					<template v-slot:cell(is_active)="{ item }">
						<div v-if="item.is_active" class="activeRunning">
							<span class="status-done"> Running </span>
							|
							<b-button
								size="sm"
								variant="outline-danger"
								@click="stopScheduledService(item.id)"
							>
								<b-icon :icon="'stop-fill'"></b-icon>
								<span>Stop</span>
							</b-button>
						</div>
						<span v-else class="status-in-progress"> Stopped </span>
					</template>

					<!-- Show details Icon -->
					<template v-slot:cell(dropdown)="{ detailsShowing, item }">
						<b-button
							size="sm"
							class="csv-btn shadow-none"
							variant="primary"
							@click="toggleDetails(item)"
						>
							<b-icon
								:icon="detailsShowing ? 'chevron-up' : 'chevron-down'"
								:style="detailsShowing ? 'color: #0094F8' : 'color: #BDBDBD'"
							></b-icon>
						</b-button>
					</template>

					<!-- Notification Details -->
					<template v-slot:row-details="{ item }">
						<div class="container text-left notifDetails">
							<b-row>
								<div class="col-4 my-3 border-right">

									
									<!-- Title -->
									<div class="grid-example">
										<div class="row-1">
											<p>Title:</p>
										</div>

										<div class="row-2">
											<p>{{ item.title }}</p>
										</div>
									</div>

									<!-- Activation Date From-->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Activation Date (From): </span>
										</div>

										<div class="row-2">
											<span>{{
												item.activation_date_from
													? new Date(item.activation_date_from).toDateString()
													: "-"
											}}</span>
										</div>
									</div>

									<!-- Activation Date To-->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Activation Date (To): </span>
										</div>

										<div class="row-2">
											<span>{{
												item.activation_date_to
													? new Date(item.activation_date_to).toDateString()
													: "-"
											}}</span>
										</div>
									</div>

									<!-- Deactivation Date From-->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Deactivation Date (From): </span>
										</div>

										<div class="row-2">
											<span>
												{{
													item.deactivation_date_from
														? new Date(
																item.deactivation_date_from
														  ).toDateString()
														: "-"
												}}</span
											>
										</div>
									</div>

									<!-- Deactivation Date To-->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Deactivation Date (To): </span>
										</div>

										<div class="row-2">
											<span>{{
												item.deactivation_date_to
													? new Date(item.deactivation_date_to).toDateString()
													: "-"
											}}</span>
										</div>
									</div>

									<!-- State -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>State(s): </span>
										</div>

										<div class="row-2">
											<span>{{
												item.state ? item.state.join(", ") : "-"
											}}</span>
										</div>
									</div>

									<!-- Status -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Status: </span>
										</div>

										<div class="row-2">
											<span>{{
												item.status ? item.status.join(", ") : "-"
											}}</span>
										</div>
									</div>

									<!-- Master -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Master: </span>
										</div>

										<div class="row-2">
											<span>{{
												item.masters ? item.masters.join(", ") : "-"
											}}</span>
										</div>
									</div>

									<!-- Retailer -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Retailer: </span>
										</div>

										<div class="row-2">
											<span>{{
												item.retailers ? item.retailers.join(", ") : "-"
											}}</span>
										</div>
									</div>

									<!-- Non-usage -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>Non-usage days range </span>
										</div>

										<div class="row-2">
											<span>{{item.non_usage_days_start}} - {{item.non_usage_days_end}}</span>
										</div>
									</div>

									<!-- App -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>App: </span>
										</div>

										<div class="row-2">
											<span>{{ item.is_app ? "Yes" : "No" }}</span>
										</div>
									</div>

									<!-- SMS -->
									<div class="grid-example mb">
										<div class="row-1">
											<span>SMS: </span>
										</div>

										<div class="row-2">
											<span>{{ item.is_sms ? "Yes" : "No" }}</span>
										</div>
									</div>
									
								</div>
								<div class="col-8 my-3">
									<b-row>
										<b-col cols="2">
											<p>Message:</p>
										</b-col>
										<b-col cols="10">
											<p>{{ item.body ? item.body : "-" }}</p>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="2">
											<p v-if="item.image_url">Image:</p>
										</b-col>
										<b-col cols="10">
											<b-img
												v-if="item.image_url"
												:src="item.image_url"
												fluid
												height="200px"
												width="200px"
											></b-img>
										</b-col>
									</b-row>
								</div>
							</b-row>
						</div>
					</template>

					<!-- Loader -->
					<template #table-busy>
						<div class="text-center mt-3">
							<b-spinner small></b-spinner>
							<strong class="ml-3">Please wait...</strong>
						</div>
					</template>
				</b-table>
				<div class="pagination">
					<b-pagination
						v-model="currentPage"
						:total-rows="currentRows"
						:per-page="perPage"
						aria-controls="dashboardTable">
					</b-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Navbar from "@/components/common/Navbar";

export default {
	name: "Dashboard",

	components: {
		Navbar,
	},

	data() {
		return {
			optionSelected: "oneTime",
			radioOptions: [
				{
					text: "One-time",
					value: "oneTime",
				},
				{ text: "Scheduled", value: "scheduled" },
			],
			isTableBusy: false,
			currentItems: [],
			notifications: [],
			selectedNotification: [],
			selectedMaster: {
				firstName: null,
				lastName: null,
			},
			selectedRetailer: {
				firstName: null,
				lastName: null,
			},
			timeout: null,
			perPage: 15,
			currentRows: 0,
			currentPage: 1,
			debouncedSearchInput: "",
			oneTimeFields: [
				{
					key: "title",
					label: "Title",
					thClass: "tableHeader",
					tdClass: "align-middle text-left titleWidth",
				},
				{
					key: "created_by.username",
					label: "Agent Name",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "type",
					label: "Type",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "customers_found",
					label: "Customers Found",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "attempted_to_send",
					label: "Attempted to Send",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "success",
					label: "Success",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "failed",
					label: "Failed",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "is_in_progress",
					label: "Status",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "created_at",
					label: "Created At",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "csv",
					label: "",
					thClass: "tableHeader buttonsColWidth",
					tdClass: "align-middle",
				},
				{
					key: "dropdown",
					label: "",
					thClass: "tableHeader buttonsColWidth",
					tdClass: "align-middle",
				},
			],
			scheduledFields: [
				{
					key: "title",
					label: "Title",
					thClass: "tableHeader",
					tdClass: "align-middle text-left titleWidth",
				},
				{
					key: "created_by.username",
					label: "Agent Name",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "type",
					label: "Type",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},

				{
					key: "created_at",
					label: "Created At",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "scheduled_days",
					label: "Frequency",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "scheduled_last_run",
					label: "Last run",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "is_active",
					label: "Status",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},

				{
					key: "dropdown",
					label: "",
					thClass: "tableHeader buttonsColWidth",
					tdClass: "align-middle",
				},
			],
		};
	},

	created() {
		this.getNotifications(1);
	},

	watch: {
		currentPage(newPage) {
			if (this.optionSelected === "oneTime") {
				this.getNotifications(newPage);
			} else {
				this.getScheduledNotifications(newPage);
			}
		},
		optionSelected(newOption) {
			if (newOption === "oneTime") {
				this.getNotifications(1);
			} else {
				this.getScheduledNotifications(1);
			}
		},
	},

	computed: {
		//for debouncing the search input
		searchQuery: {
			get() {
				return this.debouncedSearchInput;
			},
			set(val) {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.debouncedSearchInput = val;
					this.getSearchQueryTable(this.debouncedSearchInput);
				}, 800);
			},
		},
	},

	methods: {
		/**Method to only allow one row detail at a time.
		 * Also calls the get masters/retailers functions.
		 * */
		stopScheduledService(notificationId) {
			this.isTableBusy = true;
			this.axios
				.put(`/dashboard/notifications/scheduled/${notificationId}/stop`)
				.then((res) => {
					const indexNotification = this.notifications.findIndex(
						(not) => not.id === notificationId
					);
					if (indexNotification >= 0) {
						this.notifications[indexNotification].is_active = false;
					}
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				})
				.finally(() => {
					this.isTableBusy = false;
				});
		},
		toggleDetails(row) {
			if (row._showDetails) {
				this.$set(row, "_showDetails", false);
			} else {
				this.currentItems.forEach((item) => {
					this.$set(item, "_showDetails", false);
				});
				this.$nextTick(() => {
					this.$set(row, "_showDetails", true);
					this.selectedMaster = null;
					this.selectedRetailer = null;
				});
			}
		},

		/** Receives Notifications from DB */
		getNotifications(page) {
			this.isTableBusy = true;
			this.axios
				.get("/dashboard/notifications", {
					params: { page, q: this.debouncedSearchInput },
				})
				.then((res) => {
					this.notifications = res.data.data.rows.map((o) => ({
						...o,
						isExportLoading: false,
					}));
					this.currentPage = res.data.data.currentPage;
					this.currentRows = res.data.data.currentRows;

					this.formatDates();
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				})
				.finally(() => {
					this.isTableBusy = false;
				});
		},

		/** Receieves Scheduled notifications from DB */
		getScheduledNotifications(page) {
			this.isTableBusy = true;
			this.axios
				.get("/dashboard/notifications/scheduled", {
					params: { page },
				})
				.then((res) => {
					this.notifications = res.data.data.rows.map((o) => ({
						...o,
						isExportLoading: false,
					}));
					this.currentPage = res.data.data.currentPage;
					this.currentRows = res.data.data.currentRows;

					this.formatDates();
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				})
				.finally(() => {
					this.isTableBusy = false;
				});
		},
		/**Sends query of searcchbar to DB and returns filtered number
		 * of notifications with those words. Searches in Title and
		 * body of notification */
		getSearchQueryTable(val) {
			this.isTableBusy = true;
			this.axios
				.get("/dashboard/notifications", {
					params: { q: val, page: 1 },
				})
				.then((res) => {
					this.notifications = res.data.data.rows.map((o) => ({
						...o,
						isExportLoading: false,
					}));
					this.currentPage = res.data.data.currentPage;
					this.currentRows = res.data.data.currentRows;

					this.formatDates();
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				})
				.finally(() => {
					this.isTableBusy = false;
				});
		},

		/**Sends query of searcchbar to DB and returns filtered number
		 * of notifications with those words. Searches in Title and
		 * body of notification */

		/** Formats dates and puts a Capital first letter to "type"
		 * of notification
		 */
		formatDates() {
			for (let i = 0; i < this.notifications.length; i++) {
				this.notifications[i].created_at = new Date(
					this.notifications[i].created_at
				).toDateString();
				this.notifications[i].type =
					this.notifications[i].type.charAt(0).toUpperCase() +
					this.notifications[i].type.slice(1);

				if (this.notifications[i].scheduled_last_run != null)
					this.notifications[i].scheduled_last_run = new Date(
						this.notifications[i].scheduled_last_run
					).toDateString();
			}
		},

		/** Exports CSV files of selected row */
		exportCsv(i) {
			this.notifications[i].isExportLoading = true;

			let notificationId = this.notifications[i].id;

			this.axios
				.get(`/dashboard/notifications/${notificationId}/export`)
				.then((res) => {
					let contentDisposition = res.headers["content-disposition"];
					let filename = "notification_export.csv";
					if (contentDisposition) {
						filename = contentDisposition.match(/filename="(.+?)"/);
						if (filename) filename = filename[1];
					}

					let blob = new Blob([res.data], { type: "text/csv" });
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = filename;
					link.click();
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
				})
				.finally(() => {
					this.notifications[i].isExportLoading = false;
				});
		},

		rowClass(item, type) {
			if (!item || type !== "row") return;
			if (item._showDetails === true) return "rowDetailColor";
		},
	},
};
</script>
<style lang="scss">
.parent {
	width: 100%;
	height: 100%;
	background: #fff;
}

.csv-btn {
	padding: 0.1rem 0.1rem;
	background-color: #f5f5f5 !important;
	border-color: #f5f5f5 !important;
	color: #000000 !important;
}
.csv-btn:disabled {
	color: #777 !important;
}
.csv-btn:hover {
	opacity: 0.5;
}
.tableHeader {
	background-color: #0094f8 !important;
	color: #fff !important;
	border-bottom: 1px solid #dee2e6 !important;
	font-size: 10pt;
}
.notifDetails {
	font-size: 8pt;
	background-color: #f5f5f5;
	border-top: 2px solid #d9d9d9 !important;
	border-bottom: 2px solid #d9d9d9 !important;
}
.buttonsColWidth {
	width: 50px;
}
.rowDetailColor {
	background-color: #f5f5f5;
}

table.table tbody {
	font-size: small !important;
	border-left: 1px solid #d9d9d9 !important;
	border-right: 1px solid #d9d9d9 !important;
	border-bottom: 1px solid #d9d9d9 !important;
}
.titleWidth {
	max-width: 200px !important;
}

span.status-in-progress {
	font-weight: bold;
	color: #e5b336;
}

span.status-done {
	font-weight: bold;
	color: #23c723;
}

.dashboardWrapper {
	display: flex;
	gap: 1em;
	padding-bottom: 0.5em;
	justify-content: space-between;
}
.pagination {
	margin-left: auto;
}
.searchbarWrapper {
	display: flex;
	margin-bottom: 1em;
	gap: 0.5em;
	align-items: center;
}
.activeRunning {
	display: flex;
	gap: 6px;
	align-items: center;
	justify-content: center;
}
.grid-example {
	display: grid;
	width: 100%;
	grid-template-columns: 55% 45%;
	grid-template-rows: repeat(50%, 1fr);
}

.mb {
	margin-bottom: 0.8rem;
}

.row-1 {
	grid-column: 1 / 2;
	width: 100%;
}
.row-2 {
	grid-column: 2 / 2;
	width: 100%;
}
</style>
