<template>
	<div class="parent">
		<!-- Navbar -->
		<navbar />

		<!-- Container -->
		<div class="container mt-4 pb-4">
			<div class="mt-4 border-bottom dashboardWrapper">
				<h3>Internal SMS Dashboard</h3>
				<b-form-radio-group
					v-model="tableTabSelected"
					:options="tableTabs"
					button-variant="outline-primary"
					buttons>
				</b-form-radio-group>
			</div>

			<div class="mt-4">
				<!-- Searchbar -->
				<div class="searchbarWrapper">
					<b-input-group size="sm" v-if="tableTabSelected == `oneTime`">
						<b-input-group-prepend is-text>
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>
						<b-form-input type="search" placeholder="Search ..." v-model="searchQuery"></b-form-input>
					</b-input-group>
					<b-pagination
						:style="'margin-bottom:0'"
						v-model="currentPage"
						limit="3"
						:total-rows="currentRows"
						:per-page="perPage"
						aria-controls="dashboardTable">
					</b-pagination>
				</div>

				<!-- Table for normal SMS -->
				<b-table
					id="smsTable"
					ref="smsTable"
					:items="sms"
					:fields="oneTimeFields"
					:busy="isTableBusy"
					:tbody-tr-class="rowClass"
					v-model="currentItems"
					:per-page="perPage"
					responsive
					borderless
					medium
					v-if="tableTabSelected == `oneTime`">
					<!-- Icon next to username-->
					<template v-slot:[`cell(created_by_username)`]="row">
						<b-icon icon="person-circle"></b-icon>
						<span class="ml-1">{{ row.item.created_by_username }}</span>
					</template>

					<!-- Status (progress) -->
					<template v-slot:cell(is_in_progress)="row">
						<span v-if="row.item.is_in_progress" class="status-in-progress">
							In progress
						</span>
						<span v-else class="status-done"> Done </span>
					</template>

					<!-- Export to CSV buttons -->
					<template v-slot:cell(csv)="row">
						<b-button
							size="sm"
							class="csv-btn shadow-none"
							variant="primary"
							@click="exportCsv(row.index)"
							:disabled="row.item.isExportLoading || row.item.is_in_progress == 0 ? false : true">
							<b-spinner small v-if="row.item.isExportLoading" />
							<b-icon
								v-if="!row.item.isExportLoading"
								icon="file-earmark-spreadsheet-fill"
								font-scale="1"
								aria-label="Export CSV"></b-icon>
						</b-button>
					</template>

					<!-- Show details Icon -->
					<template v-slot:cell(dropdown)="{ detailsShowing, item }">
						<b-button
							size="sm"
							class="csv-btn shadow-none"
							variant="primary"
							@click="toggleDetails(item)">
							<b-icon
								:icon="detailsShowing ? 'chevron-up' : 'chevron-down'"
								:style="detailsShowing ? 'color: #0094F8' : 'color: #BDBDBD'"></b-icon>
						</b-button>
					</template>

					<!-- SMS Details -->
					<template v-slot:row-details="{ item }">
						<div class="container text-left notifDetails">
							<b-row>
								<div class="col-4 my-3 border-right">
									<!-- Masters -->
									<div class="grid-example">
										<div class="row-1">
											<p>Masters selected:</p>
										</div>
										<div class="row-2">
											<p>{{ item.masters_selected }}</p>
										</div>
									</div>

									<div class="grid-example">
										<div class="row-1">
											<p>Sent to masters:</p>
										</div>
										<div class="row-2">
											<p>{{ item.send_to_masters == 1 ? "Yes" : "No" }}</p>
										</div>
									</div>

									<!-- ZMP -->
									<div v-if="item.is_zmp">
										<div class="grid-example">
											<div class="row-1"><p>Pending masters SMS:</p></div>
											<div class="row-2">
												<p v-if="item.send_to_masters == 1">{{ item.masters_zmp_pending }} / {{ item.masters_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Delivered masters SMS:</p>
											</div>
											<div class="row-2">
												<p v-if="item.send_to_masters == 1">{{ item.masters_zmp_delivered }} / {{ item.masters_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Failed masters SMS:</p>
											</div>
											<div class="row-2">
												<p v-if="item.send_to_masters == 1">{{ item.masters_zmp_failed }} / {{ item.masters_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>
									</div>
									<!-- Twilio -->
									<div v-else>
										<div class="grid-example">
											<div class="row-1"><p>Successful masters SMS:</p></div>
											<div class="row-2">
												<p v-if="item.send_to_masters == 1">{{ item.masters_success }} / {{ item.masters_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Delivered masters SMS:</p>
											</div>
											<div class="row-2">
												<p v-if="item.send_to_masters == 1">{{ item.masters_delivered }} / {{ item.masters_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>
									</div>

									<hr class="mt-0 pt-0" />

									<!-- Retailers -->
									<div class="grid-example">
										<div class="row-1">
											<p>Retailers selected:</p>
										</div>
										<div class="row-2">
											<p>{{ item.retailers_selected }}</p>
										</div>
									</div>

									<div class="grid-example">
										<div class="row-1">
											<p>Sent to retailers:</p>
										</div>
										<div class="row-2">
											<p>{{ item.send_to_retailers == 1 ? "Yes" : "No" }}</p>
										</div>
									</div>

									<!-- ZMP -->
									<div v-if="item.is_zmp">
										<div class="grid-example">
											<div class="row-1"><p>Pending retailers SMS:</p></div>
											<div class="row-2">
												<p v-if="item.send_to_retailers == 1">{{ item.retailers_zmp_pending }} / {{ item.retailers_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Delivered retailers SMS:</p>
											</div>
											<div class="row-2">
												<p v-if="item.send_to_retailers == 1">{{ item.retailers_zmp_delivered }} / {{ item.retailers_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Failed retailers SMS:</p>
											</div>
											<div class="row-2">
												<p v-if="item.send_to_retailers == 1">{{ item.retailers_zmp_failed }} / {{ item.retailers_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>
									</div>
									<!-- Twilio -->
									<div v-else>
										<div class="grid-example">
											<div class="row-1"><p>Successful retailers SMS:</p></div>
											<div class="row-2">
												<p v-if="item.send_to_retailers == 1">{{ item.retailers_success }} / {{ item.retailers_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Delivered retailers SMS:</p></div>
											<div class="row-2">
												<p v-if="item.send_to_retailers == 1">{{ item.retailers_delivered }} / {{ item.retailers_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>
									</div>

									<hr class="mt-0 pt-0" />

									<!-- Agents -->
									<div class="grid-example">
										<div class="row-1">
											<p>Agents selected:</p>
										</div>
										<div class="row-2">
											<p>{{ item.agents_selected }}</p>
										</div>
									</div>

									<div class="grid-example">
										<div class="row-1">
											<p>Sent to agents:</p>
										</div>
										<div class="row-2">
											<p>{{ item.send_to_agents == 1 ? "Yes" : "No" }}</p>
										</div>
									</div>

									<!-- ZMP -->
									<div v-if="item.is_zmp">
										<div class="grid-example">
											<div class="row-1"><p>Pending agents SMS:</p></div>
											<div class="row-2">
												<p v-if="item.send_to_agents == 1">{{ item.agents_zmp_pending }} / {{ item.agents_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Delivered agents SMS:</p>
											</div>
											<div class="row-2">
												<p v-if="item.send_to_agents == 1">{{ item.agents_zmp_delivered }} / {{ item.agents_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Failed agents SMS:</p>
											</div>
											<div class="row-2">
												<p v-if="item.send_to_agents == 1">{{ item.agents_zmp_failed }} / {{ item.agents_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>
									</div>
									<!-- Twilio -->
									<div v-else>
										<div class="grid-example">
											<div class="row-1"><p>Successful agents SMS:</p></div>
											<div class="row-2">
												<p v-if="item.send_to_agents == 1">{{ item.agents_success }} / {{ item.agents_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Delivered agents SMS:</p></div>
											<div class="row-2">
												<p v-if="item.send_to_agents == 1">{{ item.agents_delivered }} / {{ item.agents_selected }}</p>
												<p v-else>-</p>
											</div>
										</div>
									</div>

									<hr class="mt-0 pt-0" />

									<!-- Custom phones -->
									<div class="grid-example">
										<div class="row-1">
											<p>Custom phones entered:</p>
										</div>
										<div class="row-2">
											<p>{{ item.custom_phones_entered }}</p>
										</div>
									</div>

									<!-- ZMP -->
									<div v-if="item.is_zmp">
										<div class="grid-example">
											<div class="row-1"><p>Pending custom phones SMS:</p></div>
											<div class="row-2">
												<p v-if="item.custom_phones_entered >= 1">{{ item.custom_phones_zmp_pending }} / {{ item.custom_phones_entered }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Delivered custom phones SMS:</p>
											</div>
											<div class="row-2">
												<p v-if="item.custom_phones_entered >= 1">{{ item.custom_phones_zmp_delivered }} / {{ item.custom_phones_entered }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Failed custom phones SMS:</p>
											</div>
											<div class="row-2">
												<p v-if="item.custom_phones_entered >= 1">{{ item.custom_phones_zmp_failed }} / {{ item.custom_phones_entered }}</p>
												<p v-else>-</p>
											</div>
										</div>
									</div>
									<!-- Twilio -->
									<div v-else>
										<div class="grid-example">
											<div class="row-1"><p>Successful custom phones SMS:</p></div>
											<div class="row-2">
												<p v-if="item.custom_phones_entered >= 1">{{ item.custom_phones_success }} / {{ item.custom_phones_entered }}</p>
												<p v-else>-</p>
											</div>
										</div>

										<div class="grid-example">
											<div class="row-1"><p>Delivered custom phones SMS:</p></div>
											<div class="row-2">
												<p v-if="item.custom_phones_entered >= 1">{{ item.custom_phones_success }} / {{ item.custom_phones_entered }}</p>
												<p v-else>-</p>
											</div>
										</div>
									</div>

								</div>

								<div class="col-8 my-3">
									<b-row>
										<b-col cols="2"><p>Title:</p></b-col>
										<b-col cols="10"><p>{{ item.title }}</p></b-col>
									</b-row>

									<b-row>
										<b-col cols="2"><p>Message:</p></b-col>
										<b-col cols="10"><p>{{ item.body }}</p></b-col>
									</b-row>
								</div>
							</b-row>
						</div>
					</template>

					<!-- Loader -->
					<template #table-busy>
						<div class="text-center mt-3">
							<b-spinner small></b-spinner>
							<strong class="ml-3">Please wait...</strong>
						</div>
					</template>
				</b-table>

				<!-- Table for scheduled SMS -->
				<b-table
					id="dashboardTable"
					ref="dashboardTable"
					:items="sms"
					:fields="scheduledFields"
					:busy="isTableBusy"
					:tbody-tr-class="rowClass"
					v-model="currentItems"
					:per-page="perPage"
					responsive
					borderless
					medium
					v-if="tableTabSelected == `scheduled`">
					<!-- Icon next to username-->
					<template v-slot:[`cell(created_by.username)`]="row">
						<b-icon icon="person-circle"></b-icon>
						<span class="ml-1">{{ row.item.created_by.username }}</span>
					</template>

					<!-- Frequency  -->
					<template v-slot:cell(scheduled_days)="row">
						<span>
							Every
							{{ row.item.scheduled_days === 1 ? "day" : `${row.item.scheduled_days} days` }}
							at {{ row.item.scheduled_time_pst }}:00 PST
						</span>
					</template>

					<!-- Scheduled Last Run  -->
					<template v-slot:cell(scheduled_last_run)="row">
						<span v-if="row.item.scheduled_last_run">{{row.item.scheduled_last_run }}</span>
						<span v-else>Hasn't run yet</span>
					</template>

					<!-- Status (progress) -->
					<template v-slot:cell(is_active)="{ item }">
						<div v-if="item.is_active" class="activeRunning">
							<span class="status-done"> Running </span>
							|
							<b-button
								size="sm"
								variant="outline-danger"
								@click="stopScheduledService(item.id)">
								<b-icon :icon="'stop-fill'"></b-icon>
								<span>Stop</span>
							</b-button>
						</div>
						<span v-else class="status-in-progress"> Stopped </span>
					</template>

					<!-- Show details Icon -->
					<template v-slot:cell(dropdown)="{ detailsShowing, item }">
						<b-button
							size="sm"
							class="csv-btn shadow-none"
							variant="primary"
							@click="toggleDetails(item)">
							<b-icon
								:icon="detailsShowing ? 'chevron-up' : 'chevron-down'"
								:style="detailsShowing ? 'color: #0094F8' : 'color: #BDBDBD'"
							></b-icon>
						</b-button>
					</template>

					<!-- SMS Details -->
					<template v-slot:row-details="{ item }">
						<div class="container text-left notifDetails">
							<b-row>
								<div class="col-8 my-3 border-right">
									<!-- Masters -->
									<div class="row">
										<div class="col-3"><p>Masters selected:</p></div>
										<div class="col-9"><p>{{ item.masters ? item.masters.join(', ') : '-' }}</p></div>
									</div>

									<div class="row">
										<div class="col-3"><p>Send to masters:</p></div>
										<div class="col-9"><p>{{ item.send_to_masters == 1 ? "Yes" : "No" }}</p></div>
									</div>

									<hr class="mt-0 pt-0" />

									<!-- Retailers -->
									<div class="row">
										<div class="col-3"><p>Retailers selected:</p></div>
										<div class="col-9"><p>{{ item.retailers ? item.retailers.join(', ') : '-' }}</p></div>
									</div>

									<div class="row">
										<div class="col-3"><p>Send to retailers:</p></div>
										<div class="col-9"><p>{{ item.send_to_retailers == 1 ? "Yes" : "No" }}</p></div>
									</div>

									<hr class="mt-0 pt-0" />

									<!-- Agents -->
									<div class="row">
										<div class="col-3"><p>Agents selected:</p></div>
										<div class="col-9"><p>{{ item.agents ? item.agents.join(', ') : '-' }}</p></div>
									</div>

									<div class="row">
										<div class="col-3"><p>Send to agents:</p></div>
										<div class="col-9"><p>{{ item.send_to_agents == 1 ? "Yes" : "No" }}</p></div>
									</div>

									<hr class="mt-0 pt-0" />

									<!-- Custom phones -->
									<div class="row">
										<div class="col-3"><p>Custom phone numbers:</p></div>
										<div class="col-9"><p>{{ item.custom_phone_numbers ? item.custom_phone_numbers : '-' }}</p></div>
									</div>
								</div>

								<div class="col-4 my-3">
									<b-row>
										<b-col cols="2"><p>Title:</p></b-col>
										<b-col cols="10"><p>{{ item.title }}</p></b-col>
									</b-row>

									<b-row>
										<b-col cols="2"><p>Message:</p></b-col>
										<b-col cols="10"><p>{{ item.body }}</p></b-col>
									</b-row>
								</div>
							</b-row>
						</div>
					</template>

					<!-- Loader -->
					<template #table-busy>
						<div class="text-center mt-3">
							<b-spinner small></b-spinner>
							<strong class="ml-3">Please wait...</strong>
						</div>
					</template>
				</b-table>

				<div class="pagination">
					<b-pagination
						v-model="currentPage"
						:total-rows="currentRows"
						:per-page="perPage"
						aria-controls="dashboardTable">
					</b-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Navbar from "@/components/common/Navbar";

export default {
	name: "SMS-Dashboard",

	components: {
		Navbar,
	},

	data(){
		return {
			tableTabSelected: `oneTime`,
			tableTabs: [
				{ text: `One-time`, value: `oneTime` },
				{ text: `Scheduled`, value: `scheduled` }
			],

			isTableBusy: null,

			sms: [],
			currentItems: [],

			timeout: null,
			perPage: 15,
			currentRows: 0,
			currentPage: 1,
			debouncedSearchInput: "",

			oneTimeFields: [
				{
					key: 'title',
					label: 'Title',
					thClass: 'tableHeader',
					tdClass: 'align-middle text-left title-width'
				},
				{
					key: "created_by_username",
					label: "Agent Name",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "is_in_progress",
					label: "Status",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "created_at",
					label: "Created At",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "csv",
					label: "",
					thClass: "tableHeader buttonsColWidth",
					tdClass: "align-middle",
				},
				{
					key: "dropdown",
					label: "",
					thClass: "tableHeader buttonsColWidth",
					tdClass: "align-middle",
				},
			],

			scheduledFields: [
				{
					key: "title",
					label: "Title",
					thClass: "tableHeader",
					tdClass: "align-middle text-left titleWidth",
				},
				{
					key: "created_by.username",
					label: "Agent Name",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "created_at",
					label: "Created At",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "scheduled_days",
					label: "Frequency",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "scheduled_last_run",
					label: "Last run",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "is_active",
					label: "Status",
					thClass: "tableHeader",
					tdClass: "align-middle",
				},
				{
					key: "dropdown",
					label: "",
					thClass: "tableHeader buttonsColWidth",
					tdClass: "align-middle",
				},
			],
		}
	},

	created() {
		this.getSMSs(1);
	},

	computed: {
		//for debouncing the search input
		searchQuery: {
			get() {
				return this.debouncedSearchInput;
			},
			set(val) {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.debouncedSearchInput = val;
					this.getSearchQueryTable(this.debouncedSearchInput);
				}, 800);
			},
		},
	},

	watch: {
		currentPage(newPage) {
			if (this.tableTabSelected == "oneTime")
				this.getSMSs(newPage)
			else
				this.getScheduledSms(newPage)
		},
		tableTabSelected(newOption) {
			if (newOption == "oneTime")
				this.getSMSs(1)
			else
				this.getScheduledSms(1)
		},
	},

	methods: {
		getSMSs(page) {
			this.isTableBusy = true;
			this.axios
				.get("/dashboard/internal-sms", {
					params: { page, q: this.debouncedSearchInput },
				})
				.then((res) => {

					this.sms = res.data.data.rows.map((o) => ({
						...o,
						isExportLoading: false,
					}));

					this.currentPage = res.data.data.currentPage;
					this.currentRows = res.data.data.currentRows;

					this.formatDates();
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				})
				.finally(() => {
					this.isTableBusy = false;
				});
		},

		getScheduledSms(page) {
			this.isTableBusy = true

			const config = {
				params: { page }
			}

			this.axios
				.get(`/dashboard/internal-sms/scheduled`, config)
				.then(res => {
					const data = res.data.data
					this.sms = data.rows.map(o => ({ ...o, isExportLoading: false }))
					this.currentPage = data.currentPage
					this.currentRows = data.currentRows

					this.formatDates()
				})
				.catch(error => {
					const msg = error?.response?.data?.msg ?? `Unexpected error occurred`
					this.showError(msg)
				})
				.finally(() => this.isTableBusy = false)
		},

		stopScheduledService(scheduledSmsId) {
			this.isTableBusy = true

			this.axios
				.put(`/dashboard/internal-sms/scheduled/${scheduledSmsId}/stop`)
				.then(res => {
					const i = this.sms.findIndex(o => o.id == scheduledSmsId)
					if(i >= 0)
						this.sms[i].is_active = false
				}).catch(error => {
					const msg = error?.response?.data?.msg ?? `Unexpected error occurred`
					this.showError(msg)
				})
				.finally(() => this.isTableBusy = false)
		},

		/** Exports CSV files of selected row */
		exportCsv(i) {
			this.sms[i].isExportLoading = true;

			let smsId = this.sms[i].id;

			this.axios
				.get(`/dashboard/internal-sms/${smsId}/export`)
				.then((res) => {
					let contentDisposition = res.headers["content-disposition"];
					let filename = "sms_export.csv";
					if (contentDisposition) {
						filename = contentDisposition.match(/filename="(.+?)"/);
						if (filename) filename = filename[1];
					}

					let blob = new Blob([res.data], { type: "text/csv" });
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = filename;
					link.click();
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
				})
				.finally(() => {
					this.sms[i].isExportLoading = false;
				});
		},
		/**Sends query of searcchbar to DB and returns filtered number
		 * of notifications with those words. Searches in Title and
		 * body of notification */
		getSearchQueryTable(val) {
			this.isTableBusy = true;
			this.axios
				.get("/dashboard/internal-sms", {
					params: { q: val, page: 1 },
				})
				.then((res) => {

					this.notifications = res.data.data.rows.map((o) => ({
						...o,
						isExportLoading: false,
					}));
					this.currentPage = res.data.data.currentPage;
					this.currentRows = res.data.data.currentRows;

					this.formatDates();
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				})
				.finally(() => {
					this.isTableBusy = false;
				});
		},
		toggleDetails(row) {
			if (row._showDetails) {
				this.$set(row, "_showDetails", false);
			} else {
				this.currentItems.forEach((item) => {
					this.$set(item, "_showDetails", false);
				});
				this.$nextTick(() => {
					this.$set(row, "_showDetails", true);
					this.selectedMaster = null;
					this.selectedRetailer = null;
				});
			}
		},
		/** Formats dates and puts a Capital first letter to "type"
		 * of notification
		 */
		formatDates() {
			for (let i = 0; i < this.sms.length; i++) {
				this.sms[i].created_at = new Date(
					this.sms[i].created_at
				).toDateString();

				if (this.sms[i].scheduled_last_run != null)
					this.sms[i].scheduled_last_run = new Date(
						this.sms[i].scheduled_last_run
					).toDateString();
			}
		},
		rowClass(item, type) {
			if (!item || type !== "row") return;
			if (item._showDetails === true) return "rowDetailColor";
		},
	}
}
</script>

<style>
.parent {
	width: 100%;
	height: 100%;
	background: #fff;
}
.dashboardWrapper {
	display: flex;
	gap: 1em;
	padding-bottom: 0.5em;
	justify-content: space-between;
}
.searchbarWrapper {
	display: flex;
	margin-bottom: 1em;
	gap: 0.5em;
	align-items: center;
}
.rowDetailColor {
	background-color: #f5f5f5;
}
.notifDetails {
	font-size: 8pt;
	background-color: #f5f5f5;
	border-top: 2px solid #d9d9d9 !important;
	border-bottom: 2px solid #d9d9d9 !important;
}
.title-width {
	max-width: 100px !important;
}
</style>