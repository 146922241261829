<template>
    <div class="parent">

        <!-- Navbar -->
        <navbar />

        <!-- Container "Forbidden"-->
        <div v-if="!isAdmin" class="container mt-4 pb-4">
            <h1>403 Forbidden</h1>
            <p>Sorry, you don't have access to this page</p>
        </div>
        
        <!-- Container Admin only-->
        <div v-if="isAdmin" class="container mt-4 pb-4">
            <div class="mt-4 border-bottom text-left">
               <h3>Users</h3>
           </div>
           <div align="right">
                <b-button class="mt-2 mb-2 add-user-btn" variant="primary" size="sm" @click="openNewUserModal"> 
                    <b-icon  icon="plus" font-scale="1" aria-label="Add User"></b-icon>
                Add User 
                </b-button>
            </div>
           <div>
            <!-- Table -->
            <b-table
                id="usersTable"
                :items="users"
                :fields="fields"
                :busy="isTableBusy"
                responsive
                medium
                hover
                borderless>
                <!-- Export to CSV buttons -->
                   <template v-slot:cell(delete_user)="row">
                        <b-button size="sm" class="delete-btn" variant="outline-danger" @click="deleteUserModal(row.index)">
                            <b-icon  icon="trash-fill" font-scale="1"
                                    aria-label="Delete User"></b-icon>
                        </b-button>
                   </template>
            </b-table>
            
           </div>
        </div>

       <!-- Add user form Modal -->
       <b-modal id="addUserModal" title="Add New User" centered>
            <div>
                <b-form @submit.prevent="onSubmit" novalidate>
                    <b-form-group label="Username:" label-for="username" label-size="sm" class="my-2">
                        <b-form-input
                            id="username"
                            name="username"
                            size="sm"
                            v-model="$v.form.username.$model"
                            :state="validateState('username')"
                        >
                        </b-form-input>
                        <b-form-text id="password-help-block">
                            The username must not contain special symbols, be 5 characters minimum and contain letters and numbers.
                        </b-form-text>
                        <b-form-invalid-feedback>
                            Please enter a valid username</b-form-invalid-feedback
                        >
                    </b-form-group>
                </b-form>
            </div>
            <template #modal-footer="{ cancel }">
                <div class="w-100 d-flex justify-content-between">
                    <div>
                        <b-button size="sm" variant="secondary" @click="cancel();$v.$reset();form.username=null;" :disabled="isLoading">Cancel</b-button>
                    </div>
                    <div>
                        <b-button size="sm" variant="primary" @click="onSubmit" :disabled="isLoading">Create</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
        
        <!-- New User created modal -->
        <b-modal id="newUserSuccesful" title="New User Succesfully Created" centered ok-only no-close-on-esc no-close-on-backdrop hide-header-close button-size="sm" class="justify-content-center" @hidden="newUser={}">
            <p class="text-centered">User created successfully! Details are:</p>
            <p class="font-weight-bold itemTitle mb-0 text-centered">Username: </p>
            <p class="text-centered">{{ newUser.username }}</p>
            <p class="font-weight-bold itemTitle mb-0 text-centered">Password: </p>
            <p class="text-centered">{{ newUser.password }}</p>
            <p class="text-centered">Store the password in a safe place, since it cannot be recovered again.</p>
        </b-modal>
        
        <!-- Delete user confirmation modal-->
        <b-modal id="deleteUserModal" title="Delete User Confirmation" centered button-size="sm" class="justify-content-center" @ok="deleteUser" @hidden="userId2Delete = null">
            <p>Are you sure you want to delete this user?</p>
        </b-modal>
    </div>
</template>
<script>
import Navbar from "@/components/common/Navbar";
import { validationMixin } from 'vuelidate';
import { required, minLength, alphaNum } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    
    name: "Users",

    components: {
        Navbar
    },

    computed: {
        isAdmin() {
            return this.$store.getters.userRole == "admin";
      }
    },

    data () {
        return {
            isLoading: false,
            userId2Delete: null,
            isTableBusy: false,
            newUser: {
                username: null,
                password: null
            },
            form: {
                username: null
            },
            users: [],
            fields: [
                {key: 'id', label: 'ID', thClass: 'bg-light text-muted font-weight-light border-bottom', tdClass: 'border-bottom align-middle'},
                {key: 'username', label: 'Username', thClass: 'bg-light text-muted font-weight-light border-bottom', tdClass: 'border-bottom align-middle'},
                {key: 'last_sign_in', label: 'Last Sign-in', thClass: 'bg-light text-muted font-weight-light border-bottom', tdClass: 'border-bottom align-middle'},
                {key: 'created_by_user.username', label: 'Created By', thClass: 'bg-light text-muted font-weight-light border-bottom', tdClass: 'border-bottom align-middle'},
                {key: 'created_at', label: 'Created At', thClass: 'bg-light text-muted font-weight-light border-bottom', tdClass: 'border-bottom align-middle'},
                {key: 'delete_user', label: '', thClass: 'bg-light text-muted font-weight-light border-bottom', tdClass: 'border-bottom align-middle'},
            ]
        }
    },

    created() {
       this.getUsers();
    },

    validations: {
        form: {
            username: { 
                required,
                minLength: minLength(5),
                alphaNum,
            },
        }
    },
    methods: {

        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },

        openNewUserModal() {
            this.$bvModal.show('addUserModal');
        },

        getUsers() {
            this.isTableBusy = true;

            this.axios
                .get('/dashboard/users')
                .then((res) => {
                    this.users = res.data.data;
                    this.formatDates();
                })
                .catch((error) => {
                    const message = error?.response?.data?.msg ?? "An unexpected error occurred";
                    this.showError(message);
                    console.error(error);
                })
                .finally(() => {
                    this.isTableBusy = false;
                });
        },

        onSubmit() {
            this.$v.form.$touch();

            if (this.$v.form.$anyError) {
                return;
            }

            this.isLoading = true;
            let body = {
                username: this.form.username
            }

            this.axios
                .post('/dashboard/users', body)
                .then((res) => {
                    this.newUser = res.data.data;
                    this.$bvModal.hide('addUserModal');
                    this.$bvModal.show('newUserSuccesful');
                    this.getUsers();
                })
                .catch((error) => {
                    const message = error?.response?.data?.msg ?? "An unexpected error ocurred";
                    this.showError(message);
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.$v.$reset();
                    this.form.username = null;
            });

        },

        deleteUserModal(i) {
            this.userId2Delete = this.users[i].id;
            this.$bvModal.show('deleteUserModal');
            
        },

        deleteUser() {
            this.axios
                .delete(`/dashboard/users/${this.userId2Delete}`)
                .then((res) => {
                    this.getUsers();
                })
                .catch((error) => {
                    const message = error?.response?.data?.msg ?? "An unexpected error ocurred";
                    this.showError(message);
                    console.error(error);
                })
                .finally(() => {
                    this.userId2Delete = null;
             });
        },

        formatDates() {
            for(let i=0; i<this.users.length; i++) {
                this.users[i].created_at = new Date(this.users[i].created_at).toDateString();
                if(this.users[i].last_sign_in) this.users[i].last_sign_in = new Date(this.users[i].last_sign_in).toDateString();
            }
        },
    }
}
</script>
<style scoped lang="scss">
.parent {
  width: 100%;
  height: 100%;
  background: #fff;
}
.add-user-btn {
    padding: 0.4rem 0.9rem !important;
}
.delete-btn {
    padding: 0.3rem 0.3rem !important;
}
.itemTitle {
    font-size: small;
    
}
.text-centered {
    text-align: center !important;
}
</style>