import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=3207f614&scoped=true&"
import script from "./NotFound.vue?vue&type=script&lang=js&"
export * from "./NotFound.vue?vue&type=script&lang=js&"
import style0 from "./NotFound.vue?vue&type=style&index=0&id=3207f614&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3207f614",
  null
  
)

export default component.exports