<template>
  <div class="parent">
    <navbar />

    <div class="container mt-5">
      <div class="row">
        <div class="col-12 text-center">
          <h5 class="font-weight-normal">
            It looks like this page doesn't exist!
          </h5>
          <br />
          <br />
          <br />
          <b-button href="/" variant="primary">Go back home</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/common/Navbar";
export default {
  name: "NotFound",
  components: { Navbar },
};
</script>

<style scoped lang="scss">
.parent {
  width: 100%;
  height: 100%;
  background: #fff;
}
</style>
