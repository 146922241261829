import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueAxios from 'vue-axios'
import axios from 'axios'
import { Vuelidate } from 'vuelidate'

Vue.config.productionTip = false

//Import mixins
import "@/mixins/toastsMixin";

// Import global styling
import '@/assets/global.css'

// Import Bootstrap and BootstrapVue CSS files
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make bootstrap available in the entire project
Vue.use(BootstrapVue)

// We could optionally install BootstrapVue icons plugin
Vue.use(IconsPlugin)

// Add axios and vue-axios (axios will be accessible using
// Vue.axios)
Vue.use(VueAxios, axios);

// Configure Axios base URL
Vue.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL || "";

// Configure auth interceptor
import authInterceptor from '@/interceptors/auth'
authInterceptor()

// Add Vuelidate
Vue.use(Vuelidate)

// Run logic to get JWT from the local storage
let jose = require('jose')

// Check if we're logged in, and if the JWT is still valid
let jwt = localStorage.getItem('session')
if (jwt) {
  try {
    let jwtDecoded = jose.decodeJwt(jwt)

    // JWT is valid, check expiration
    let now = new Date()
    let expMs = jwtDecoded.exp * 1000
    let expiresInMs = expMs - now.getTime()

    // Expired? Logout
    if (expiresInMs < 0) {
      // TODO - Fix this route if needed (login)
      store.dispatch('logout').then(() => {
        router.push({ name: 'login' })
      })
    } else {
      // Valid... Load information from the JWT into the store, and
      // redirect if needed
      store.dispatch('login', jwt).then(() => {
        // TODO - Fix this route if needed (main)
        if (router.currentRoute.name === 'login')
          router.push({ name: 'main' })
      })
    }

  } catch (e) {
    // Invalid JWT...
    // TODO - Fix this route if needed (login)
    store.dispatch('logout').then(() => {
      router.push({ name: "login" })
    })
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
