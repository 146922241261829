import router from '../router'
import axios from 'axios'
import store from '@/store'
import Vue from 'vue'

export default function init() {
	axios.interceptors.request.use(
		(config) => {
			const token = store.getters.jwtState
			if (token)
				config.headers['x-access-token'] = token;
			return config
		},
		err => Promise.reject(err)
	)

	axios.interceptors.response.use(response => {
		return response
	}, error => {
		let url = error.config.url
		let status = error.response.status

		if(url != '/dashboard/auth/sign-in' && status == 401) {
			store.dispatch('logout').then(() => {
				router.push({ name: 'login' })
			})
		}

		Promise.reject(error)
	})
}