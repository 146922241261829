import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router/index'

Vue.use(Vuex)

let jose = require('jose')

export default new Vuex.Store({
  state: {
    isSignedIn: false,
    signedInJwt: null,
    user: null
  },

  getters: {
    loginState: (state) => state.isSignedIn,
    jwtState: (state) => state.signedInJwt,
    userRole: state => {
      return state.user?.role ?? {}
    },
  },

  mutations: {
    login(state, payload) {
      state.isSignedIn = true
      state.signedInJwt = payload.jwt
    },

    logout(state) {
      state.isSignedIn = false
      state.signedInJwt = null
    },

    setUser(state, payload) {
      state.user = payload;
    }
  },

  actions: {
    // To sign in with an already validated JWT
    login({ commit, dispatch }, jwt) {
      let jwtDecoded = jose.decodeJwt(jwt)

      let now = new Date()
      let expMs = jwtDecoded.exp * 1000
      let expiresInMs = expMs - now.getTime()

      // Set expiration timeout to sign out automatically
      // NOTE: If greater than maximum possible value, omit
      if (expiresInMs < 2147483647) {
        setTimeout(() => {
          dispatch('logout')
        }, expiresInMs)
      }

      localStorage.setItem('session', jwt)

      commit('login', {
        jwt: jwt,
        decoded: jwtDecoded
      })

      commit("setUser", jwtDecoded)

      if (router.currentRoute.name === 'login')
        router.push({ name: "Dashboard" })

    },

    logout({ commit }) {
      localStorage.removeItem('session')

      commit('logout')

      if (router.currentRoute.name !== 'login')
        router.push({ name: "login" })
    }
  },

  modules: {
  }
})
