<template>
	<div class="parent">
		<!-- Loading overlay screen-->
		<b-overlay :show="showOverlay" rounded="sm">
			<!--Confirm send Notification-->
			<b-modal
				id="applyNotificationConfirmation"
				ok-only
				centered
				title="Confirmation"
			>
				<div v-if="notification_time == 'scheduled'">
					As of right now, there were {{ enrollmentsFound }} enrollments found,
					and the notification can be sent to {{ totalFcmTokens }} devices.
					These numbers might vary at the time of running the scheduled
					notification. Click on submit to schedule this notification.
				</div>
				<div v-else>
					There were {{ enrollmentsFound }} enrollments found, and the
					notification will be sent to {{ totalFcmTokens }} devices. Click on
					submit to send the notification.
				</div>
			</b-modal>

			<!--Enrollment and devices number message SMS-->
			<b-modal
				id="applyNotificationConfirmationSMS"
				ok-only
				centered
				title="Confirmation"
			>
				<div v-if="notification_time == 'scheduled'">
					As of right now, there were {{ enrollmentsFound }} enrollments found,
					and the SMS text can be sent to {{ enrollmentsFound }} customers.
					These numbers might vary at the time of running the scheduled
					notification. Click on submit to schedule this notification.
				</div>
				<div v-else>
					There were {{ enrollmentsFound }} enrollments found, the SMS text will
					be sent to {{ enrollmentsFound }} customers. Click on submit to send
					the notification.
				</div>
			</b-modal>

			<!--Enrollment and devices number message Both-->
			<b-modal
				id="applyNotificationConfirmationBoth"
				ok-only
				centered
				title="Confirmation"
			>
				<div v-if="notification_time == 'scheduled'">
					As of right now, there were {{ enrollmentsFound }} enrollments found,
					and the SMS text can be sent to {{ enrollmentsFound }} customers, and
					the notification can be sent to {{ totalFcmTokens }} devices. Click on
					submit to schedule this notification.
				</div>
				<div v-else>
					There were {{ enrollmentsFound }} enrollments found, the SMS text will
					be sent to {{ enrollmentsFound }} customers and the notification will
					be sent to {{ totalFcmTokens }} devices. Click on submit to send the
					notification.
				</div>
			</b-modal>

			<!--Process in background message-->
			<b-modal id="processInBackgroundMessage" ok-only title="Confirmation">
				<div>
					This is a high volume request. Notification to each and every customer
					is being sent in the background, and it may take several minutes. You
					may close this window.
				</div>
			</b-modal>

			<!-- Navbar-->
			<navbar />

			<!-- Nav Text/Image notifications-->
			<div class="container mt-4">
				<b-tabs content-class="mt-3">
					<b-tab
						title="Text Notification"
						active
						@click="
							isImageNotification = false;
							form.image = null;
							imagePreview = null;
							$refs.image.reset();
						"
					></b-tab>
					<b-tab
						title="Image Notification"
						@click="isImageNotification = true"
					></b-tab>
				</b-tabs>
			</div>

			<!--Container for 2 column grid on page-->
			<div class="container">
				<div class="row text-left">
					<div class="col-7 mt-4 border-right">
						<div>
							<h2>Add Notification</h2>
						</div>

						<!-- Form -->
						<b-form
							action="#"
							@submit.prevent=""
							novalidate
							enctype="multipart/form-data"
						>
							<!--Title input-->
							<b-form-group
								label="Title"
								label-class="mb-0"
								label-for="title"
								label-size="sm"
								class="my-2"
							>
								<b-form-input
									id="title"
									name="title"
									size="sm"
									v-model="$v.form.title.$model"
									:state="validateState('title')"
									placeholder="Title..."
									@input="formChanged = true"
								/>
								<b-form-invalid-feedback
									>Please provide a title</b-form-invalid-feedback
								>
							</b-form-group>

							<!--Dates-->
							<div>
								<!--Activation date Datepickers-->
								<label for="activationDate" class="d-block col-form-label-sm mb-0">
									Activation Date Range
								</label>
							</div>
							<div class="row">
								<div class="col">
									<b-form-input
										type="date"
										id="activationDateFrom"
										size="sm"
										v-model="$v.form.activationDateFrom.$model"
										:state="validateState('activationDateFrom')"
										@change="formChanged = true"
									>
									</b-form-input>
									<b-form-invalid-feedback>
										Please provide a date
									</b-form-invalid-feedback>
								</div>
								<div class="col">
									<b-form-group class="my-0">
										<b-form-input
											type="date"
											id="activationDateTo"
											size="sm"
											v-model="$v.form.activationDateTo.$model"
											:state="validateState('activationDateTo')"
											@change="formChanged = true"
										>
										</b-form-input>
										<b-form-invalid-feedback>
											Please provide a date
										</b-form-invalid-feedback>
									</b-form-group>
								</div>
							</div>

							<!--Dectivation date Datepickers-->
							<div class="row text-left">
								<div class="col-12">
									<label for="DeactivationDate-MM" class="d-block col-form-label-sm mb-0 mt-2">
										Deactivation Date Range
									</label>
								</div>
							</div>
							<div class="row text-left">
								<div class="col-6">
									<b-form-input
										type="date"
										id="deActivationDateFrom"
										size="sm"
										v-model="$v.form.deActivationDateFrom.$model"
										:state="validateState('deActivationDateFrom')"
										@change="formChanged = true"
									>
									</b-form-input>
									<b-form-invalid-feedback
										>Please provide a date</b-form-invalid-feedback
									>
								</div>
								<div class="col-6">
									<b-form-group class="my-0">
										<b-form-input
											type="date"
											id="deActivationDateTo"
											size="sm"
											v-model="$v.form.deActivationDateTo.$model"
											:state="validateState('deActivationDateTo')"
											@change="formChanged = true"
										>
										</b-form-input>
										<b-form-invalid-feedback
											>Please provide a date</b-form-invalid-feedback
										>
									</b-form-group>
								</div>
							</div>

							<!--Selects-->

							<!--States-->
							<b-form-group
								label="State"
								label-class="mb-0"
								label-for="state"
								label-size="sm"
								class="mt-2"
							>
								<!-- <b-form-select
									id="state"
									size="sm"
									v-model="form.state"
									@change="formChanged = true"
								>
									<option :value="null" disabled>
										-- Please select an option --
									</option>
									<option :value="null">No state</option>
									<option v-for="state in states" :value="state" :key="state">
										{{ state }}
									</option>
								</b-form-select> -->
								<multiselect
									id="state"
									v-model="form.state"
									track-by=""
									placeholder="-- Please select an option --"
									label=""
									:options="states"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:value="states[0]"
									:groupSelect="true"
									group-values="states"
									group-label="select_all"
								>
								</multiselect>
							</b-form-group>

							<!--Status-->
							<b-form-group
								label="Status"
								label-class="mb-0"
								label-for="status"
								label-size="sm"
								class="mt-0"
							>
								<!-- <b-form-select
									id="status"
									size="sm"
									v-model="form.status"
									@change="formChanged = true"
								>
									<option :value="null" disabled>
										-- Please select an option --
									</option>
									<option :value="null">No status</option>
									<option
										v-for="status in statuses"
										:value="status"
										:key="status"
									>
					@change="formChanged = true" 
										{{ status }}
									</option>
								</b-form-select> -->

								<multiselect
									id="status"
									v-model="form.status"
									track-by=""
									placeholder="-- Please select an option --"
									label=""
									:options="statuses"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:value="statuses"
									:groupSelect="true"
									group-values="statuses"
									group-label="select_all"
								>
								</multiselect>
							</b-form-group>

							<!--Masters-->
							<b-form-group
								label="Master"
								label-class="mb-0"
								label-for="supervisor"
								label-size="sm"
								class="mt-0"
							>
								<!-- <b-form-select
									id="master"
									size="sm"
									v-model="form.master"
									@change="getRetailers(form.master)"
								>
									<option :value="null" disabled>
										-- Please select an option --
									</option>
									<option :value="null">No master</option>
									<option
										v-for="master in masters"
										:value="master.masterId"
										:key="master.masterId"
									>
										{{ master.firstName }} {{ master.lastName }}
									</option>
								</b-form-select> -->

								<multiselect
									id="master"
									v-model="form.master"
									track-by="fullname"
									placeholder="-- Please select an option --"
									label="fullname"
									:options="masters"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:value="masters.masterId"
									:groupSelect="true"
									group-values="masters"
									group-label="select_all"
								>
								</multiselect>
							</b-form-group>

							<!--Retailers-->
							<b-form-group
								label="Retailer"
								label-class="mb-0"
								label-for="retailer"
								label-size="sm"
								class="mt-0"
							>
								<multiselect
									id="retailer"
									v-model="form.retailer"
									track-by="fullname"
									placeholder="-- Please select an option --"
									label="fullname"
									:options="retailers"
									:multiple="true"
									:close-on-select="false"
									:clear-on-select="false"
									:preserve-search="true"
									:value="retailers.retailerId"
									:disabled="isDisabled"
									:groupSelect="true"
									group-values="retailers"
									group-label="select_all"
								>
								</multiselect>
							</b-form-group>

							<!-- Non usage -->
							<div>
								<label class="d-block col-form-label-sm mb-0">
									Non-usage days range
								</label>
							</div>
							<div class="row">
								<div class="col">
									<b-form-group>
										<b-form-input
											type="number"
											id="nonUsageDaysStart"
											size="sm"
											v-model="$v.form.nonUsageDaysStart.$model"
											:state="validateState('nonUsageDaysStart')"
											@input="formChanged = true">
										</b-form-input>
										<b-form-invalid-feedback>
											Please type non-usage days range
										</b-form-invalid-feedback>
									</b-form-group>
								</div>
								<div class="col">
									<b-form-group>
										<b-form-input
											type="number"
											id="nonUsageDaysEnd"
											size="sm"
											v-model="$v.form.nonUsageDaysEnd.$model"
											:state="validateState('nonUsageDaysEnd')"
											@input="formChanged = true">
										</b-form-input>
										<b-form-invalid-feedback>
											Please type non-usage days range
										</b-form-invalid-feedback>
									</b-form-group>
								</div>
							</div>

							<!-- Type of Notification Raidals -->
							<b-row>
								<div class="col">
									<b-form-group
										label="Notification Type"
										label-size="sm"
										class="mt-3">
										<b-form-radio-group
											v-model="notification_type"
											:options="typeOfNotification"
											size="sm"
											@input="formChanged = true"></b-form-radio-group>
									</b-form-group>

									<!-- Recipient Type Radials-->
									<b-form-group
										label="Send Notification By:"
										label-size="sm"
										class="mt-3">
										<b-form-radio-group
											v-model="form.type"
											:options="radialOptions"
											size="sm"
											@input="formChanged = true"></b-form-radio-group>
									</b-form-group>

									<div class="mt-2 mb-3" style="font-size: 14px">
										* Winback notifications are under maintenance
									</div>

								</div>
								<div class="col">
									<b-form-group
										label="Notification Time"
										label-size="sm"
										class="mt-3">
										<b-form-radio-group
											v-model="notification_time"
											:options="typeOfNotificationTime"
											size="sm"
											@input="formChanged = true"></b-form-radio-group>
									</b-form-group>
									<b-form-group
										v-if="notification_time === 'scheduled'"
										label="Scheduled Days"
										label-class="mb-0"
										label-for="scheduledDays"
										label-size="sm"
										class="my-2">
										<b-form-input
											id="scheduledDays"
											name="scheduledDays"
											size="sm"
											v-model="$v.form.scheduledDays.$model"
											:state="validateState('scheduledDays')"
											placeholder="Scheduled days..."
											number
											@input="formChanged = true" />

										<b-form-select
											v-model="time_selected"
											:options="time"
											class="time_picker">
										</b-form-select>
										<b-form-select
											v-model="am_pm_selected"
											:options="am_pm"
											class="time_picker">
										</b-form-select>
									</b-form-group>
								</div>
							</b-row>

							<!--Message Input-->
							<b-form-group
								label="Message"
								label-class="mb-0"
								label-for="message"
								label-size="sm"
								class="mt-0"
							>
								<b-form-textarea
									id="message"
									v-model="$v.form.message.$model"
									:state="validateState('message')"
									placeholder="Message..."
									rows="3"
									max-rows="12"
									size="sm"
									@input="formChanged = true"
								>
								
								</b-form-textarea>
								<div class="limiter mt-2"><span>{{charactersCount}}</span></div>
								<b-form-invalid-feedback v-if="!$v.form.message.required">
									Please enter a message
								</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.form.message.maxLength">
									Message must be 160 characters maximum.
								</b-form-invalid-feedback>
							</b-form-group>

							<!-- Browse File -->
							<b-form-group
								v-if="isImageNotification"
								label="Image"
								label-class="mb-0"
								label-for="image"
								label-size="sm"
								class="mt-0">
								<b-form-file
									id="image"
									ref="image"
									@change="onFileInput($event)"
									placeholder="Choose an image or drop it here..."
									drop-placeholder="Drop image here..."
									accept=".jpg, .png"
									size="sm">
								</b-form-file>
								<b-button
									class="rmv-btn mt-2"
									variant="outline-danger"
									size="sm"
									:disabled="!form.image"
									@click="
										form.image = null;
										imagePreview = null;
										$refs.image.reset();
									">
									Remove Image
								</b-button>
							</b-form-group>

							<!-- Apply/Submit buttons-->
							<div class="row">
								<div class="col">
									<button
										:disabled="isLoadingApply"
										type="submit"
										class="btn w-100 mt-2 mb-5 button1 btn-sm"
										@click="applyNotificationModal()">
										Apply
									</button>
								</div>
								<div class="col">
									<button
										type="submit"
										:disabled="submitButtonDisable()"
										class="btn w-100 mt-2 mb-5 button1 btn-sm"
										@click="onSubmitNotification()">
										Submit
									</button>
								</div>
							</div>
						</b-form>
					</div>

					<div class="col-5 mt-4">
						<!-- Notification Preview -->
						<div v-if="form.type == 'app' || form.type == 'both'">
							<div>
								<h2>App Notification Preview</h2>
							</div>
							<b-card
								bg-variant="light"
								class="mt-4"
								:header="
									$v.form.title.$model
										? $v.form.title.$model
										: `Notification Title`
								"
								aria-placeholder="holo">
								<b-card-text>{{
									$v.form.message.$model
										? $v.form.message.$model
										: `Notification message`
								}}</b-card-text>
								<b-card-img
									v-if="imagePreview"
									:src="imagePreview"
									alt="Image"
									bottom
								></b-card-img>
							</b-card>
						</div>

						<!-- SMS Preview -->
						<div v-if="form.type == 'sms' || form.type == 'both'">
							<div class="mt-5">
								<h2>SMS Preview</h2>
							</div>
							<b-card
								bg-variant="light"
								class="mt-3 mb-4"
								aria-placeholder="holo"
							>
								<b-card-text>{{
									$v.form.message.$model
										? $v.form.message.$model
										: `Notification message`
								}}</b-card-text>
							</b-card>
						</div>
					</div>
				</div>
			</div>
		</b-overlay>
	</div>
</template>

<script>
import Navbar from "@/components/common/Navbar";
import { validationMixin } from "vuelidate";
import Multiselect from "vue-multiselect";

// register globally
// Vue.component('multiselect', Multiselect)
import {
	required,
	requiredIf,
	maxLength,
	integer,
	minValue,
} from "vuelidate/lib/validators";

export default {
	mixins: [validationMixin],

	name: "Notifications",
	components: {
		Navbar,
		Multiselect,
	},

	computed: {
		charactersCount() {
			if(this.form.message) {
				var char = this.form.message.length
				return char + " characters used";
			}
		}
	},

	data() {
		return {
			/*Flags*/
			isLoadingApply: false, //To disable "apply" button
			isImageNotification: false, //To either show/not show file input for image
			showOverlay: false, //To show loading overlay
			formChanged: false, //If any form input, datepicker or select is changed
			isMasterSelected: false, //To enable/disable retailer select
			isFormApplied: false, //To know if the form went through the "apply" method and enable "submit" button

			value: null,
			isDisabled: true,

			/*Arrays for GET methods*/
			states: [
				{
					select_all: "Select All",
					states: []
				}
			],
			statuses: [
				{
					select_all: "Select All",
					statuses: []
				}
			],
			masters: [
				{
					select_all: "Select All",
					masters: []
				}
			],
			masters_id: [],
			selected_statuses: [],
			retailers: [
				{
					select_all: "Select All",
					retailers: [],
				},
			],

			am_pm_selected: "pm",
			time_selected: "01",
			time: [
				{ value: "01", text: "01" },
				{ value: "02", text: "02" },
				{ value: "03", text: "03" },
				{ value: "04", text: "04" },
				{ value: "05", text: "05" },
				{ value: "06", text: "06" },
				{ value: "07", text: "07" },
				{ value: "08", text: "08" },
				{ value: "09", text: "09" },
				{ value: "10", text: "10" },
				{ value: "11", text: "11" },
				{ value: "12", text: "12" },
			],
			am_pm: [
				{ value: "am", text: "AM" },
				{ value: "pm", text: "PM" },
			],

			/*Misc*/
			imagePreview: null, //This variable stores the image url so it can be seen on the preview section
			enrollmentsFound: null, //Used to store enrollmentsFound from responses
			totalFcmTokens: null, //Used to store totalFcmTokens from responses

			radialOptions: [
				//radial options for the form
				{ text: "App", value: "app" },
				{ text: "SMS", value: "sms" },
				{ text: "Both", value: "both" },
			],

			typeOfNotification: [
				{ text: "Normal", value: "normal" },
				{ text: "Winback", value: "winback" },
			],
			typeOfNotificationTime: [
				{ text: "One Time", value: "oneTime" },
				{ text: "Scheduled", value: "scheduled" },
			],

			notification_type: "normal",
			notification_time: "oneTime",
			notification_hour: "",

			form: {
				title: null,
				activationDateFrom: null,
				activationDateTo: null,
				deActivationDateFrom: null,
				deActivationDateTo: null,
				state: null,
				status: null,
				master: null,
				retailer: null,
				scheduledDays: 1,
				nonUsageDaysStart: null,
				nonUsageDaysEnd: null,
				type: "app",
				message: null,
				image: null,
			},
		};
	},

	watch: {
		"form.master": {
			handler: function (val, oldVal) {
				this.masters_id = [];

				if(val != null && val.length != 0){
					for (const master of val) {
						this.masters_id.push(master.m_id);
					}
					let statusOptions = String(this.masters_id);
					this.getRetailers(statusOptions);
				} else {
					this.isDisabled = true;
                    this.retailers[0].retailers = []
                    this.form.retailer = []
				}
			},

			deep: true,
		},
	},
	/** These methods are called when the page gets created */
	created() {
		this.getStates();
		this.getStatuses();
		this.getMasters();
	},

	validations() {
		return {
			form: {
				title: { required },
				scheduledDays: { required, integer, minValue: minValue(1) },
				message: {
					required: requiredIf(function () {
						return this.form.type == "sms" || this.form.type == "both";
					}),
					maxLength:
						this.form.type == "sms" || this.form.type == "both"
							? maxLength(160)
							: false,
				},

				nonUsageDaysStart: {
					// true -> valid
					fullValidation: function(val) {
						if(val === null && this.form.nonUsageDaysEnd === null)
							return true

						if(!(/^\d+$/.test(val)))
							return false

						if(parseInt(val) <= parseInt(this.form.nonUsageDaysEnd))
							return true

						return false
					},
				},

				nonUsageDaysEnd: {
					fullValidation: function(val) {
						if(val === null && this.form.nonUsageDaysStart === null)
							return true

						if(!(/^\d+$/.test(val)))
							return false

						if(parseInt(val) >= parseInt(this.form.nonUsageDaysStart))
							return true

						return false
					}
				},

				/**Required validations for dates: If one of them gets a value the other one must be also inputed */
				activationDateFrom: {
					required: requiredIf(function () {
						return this.form.activationDateTo != null;
					}),
				},

				activationDateTo: {
					required: requiredIf(function () {
						return this.form.activationDateFrom != null;
					}),
				},

				deActivationDateFrom: {
					required: requiredIf(function () {
						return this.form.deActivationDateTo != null;
					}),
				},

				deActivationDateTo: {
					required: requiredIf(function () {
						return this.form.deActivationDateFrom != null;
					}),
				},
			},
		};
	},

	methods: {
		//Quick method to enable/disable "submit" button
		submitButtonDisable() {
			return this.isFormApplied ? this.formChanged : true;
		},

		/**Method that handles the event when a file is uploaded.
		 * Assigns the image URL to imagePreview variable and
		 * stores the file object on form.image
		 */
		onFileInput(event) {
			const data = URL.createObjectURL(event.target.files[0]);
			this.imagePreview = data;
			this.form.image = event.target.files[0];
		},

		validateState(name) {
			const { $dirty, $error } = this.$v.form[name];

			return $dirty ? !$error : null;
		},

		/**
		 * Getters
		 */
		getStates() {
			this.axios
				.get(`/dashboard/states`)
				.then((res) => {
					this.states[0].states = res.data.data;
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				});
		},
		getStatuses() {
			this.axios
				.get(`/dashboard/statuses`)
				.then((res) => {
					this.statuses[0].statuses = res.data.data;
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				});
		},
		getMasters() {
			this.axios
				.get(`/dashboard/masters?status=Active,Inactive`)
				.then((res) => {
					const temp = res.data.data.map((m) => {
						return { ...m, fullname: `${m.fname} ${m.lname}` };
					});
					this.masters[0].masters = temp;
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				});
		},

		/**GET Retailers only gets called when a master is selected in the form */
		getRetailers(incomingOptions) {
			this.formChanged = true;
			this.isMasterSelected = true;
			this.isDisabled = false;
			this.axios
				.get("dashboard/retailers?masters=" + incomingOptions + "&status=Active,Inactive")
				.then((res) => {
					const temp = res.data.data.map((m) => {
						return { ...m, fullname: `${m.fname} ${m.lname}` };
					});

					this.retailers[0].retailers = temp;
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				});
		},

		/**This method is called when the "apply"
		 * button is clicked, checks verifications
		 * before calling the GET method.*/
		applyNotificationModal() {
			this.$v.form.$touch();

			if (this.$v.form.$anyError) {
				return;
			}
			this.getEnrollmentsFound();
		},

		/**This method is called when the form has
		 * been verified and gets the number of
		 * enrrollments and devices to display
		 * on a modal*/
		getEnrollmentsFound() {
			this.isLoadingApply = true;
			this.showOverlay = true;

			let body = {
				activationDateFrom: this.form.activationDateFrom,
				activationDateTo: this.form.activationDateTo,
				deactivationDateFrom: this.form.deActivationDateFrom,
				deactivationDateTo: this.form.deActivationDateTo,
				nonUsageDaysStart: this.form.nonUsageDaysStart,
				nonUsageDaysEnd: this.form.nonUsageDaysEnd
				//type: this.form.type
			};
			if(this.form.retailer != null && this.form.retailer.length){
				body.retailerId = this.form.retailer.map((m) => m.r_id).join(",")
			}
			
			if(this.form.status != 0){
				body.status = this.form.status?.join(",")
			}

			if(this.form.state != 0){
				body.state= this.form.state?.join(",")
			}

			if(this.form.master != null && this.form.master.length){
				body.masterId = this.form.master.map((m) => m.m_id).join(",")
			}
			
			this.axios
				.get(`/dashboard/notifications/${this.notification_type}`, {
					params: body,
				})
				.then((res) => {
					//get enrollments and FCM tokens
					this.enrollmentsFound = res.data.data.enrollmentsFound;
					this.totalFcmTokens = res.data.data.totalFcmTokens;

					//show the specific modal depending on the type of notificiation. app/sms/both
					switch (this.form.type) {
						case "app":
							this.$bvModal.show("applyNotificationConfirmation");
							break;
						case "sms":
							this.$bvModal.show("applyNotificationConfirmationSMS");
							break;
						case "both":
							this.$bvModal.show("applyNotificationConfirmationBoth");
							break;
					}

					this.isFormApplied = true;
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				})
				.finally(() => {
					this.isLoadingApply = false;
					this.showOverlay = false;
					this.formChanged = false;
				});
		},

		/**Method to POST notification */
		onSubmitNotification() {
			this.showOverlay = true;
			/** Post url is by default one-time, and checks by notification_type */
			let postUrl = `/dashboard/notifications/${this.notification_type}`;

			// console.log(this.form.state.join(','));
			// console.log(this.form.status.join(','));
			// console.log(this.form.master.map(m => m.masterId).join(','));
			const formData = new FormData();
			if (this.form.title) formData.append("title", this.form.title);
			if (this.form.activationDateFrom)
				formData.append("activationDateFrom", this.form.activationDateFrom);
			if (this.form.activationDateTo)
				formData.append("activationDateTo", this.form.activationDateTo);
			if (this.form.deActivationDateFrom)
				formData.append("deactivationDateFrom", this.form.deActivationDateFrom);
			if (this.form.deActivationDateTo)
				formData.append("deactivationDateTo", this.form.deActivationDateTo);
			
			if (this.form.state != 0 && this.form.state != null) formData.append("state", this.form.state.join(","));
			
			if (this.form.status != 0 && this.form.status != null){
				formData.append("status", this.form.status.join(","));
			}				

			if(this.form.master != null && this.form.master.length){
				formData.append("masterId", this.form.master.map((m) => m.m_id).join(","));
			}

			if(this.form.retailer != null && this.form.retailer.length){
				formData.append("retailerId", this.form.retailer.map((m) => m.r_id).join(","));
			}

			if(this.form.nonUsageDaysStart)
				formData.append("nonUsageDaysStart", this.form.nonUsageDaysStart)
			if(this.form.nonUsageDaysEnd)
				formData.append('nonUsageDaysEnd', this.form.nonUsageDaysEnd)
			if (this.form.type) formData.append("type", this.form.type);
			if (this.form.message) formData.append("body", this.form.message);
			if (this.form.image) formData.append("image", this.form.image);
			if (this.notification_time === "scheduled") {
				if (this.form.scheduledDays)
					formData.append("scheduledDays", this.form.scheduledDays);
				if (this.notification_type)
					formData.append("type2", this.notification_type);

				if (this.time_selected) {
					this.time_selected = parseInt(this.time_selected);

					if (this.am_pm_selected == "am") {
						if(this.time_selected == 12){
							this.time_selected = 0
						}
					}

					if (this.am_pm_selected == "pm") {
						this.time_selected = this.time_selected + 12;
						if(this.time_selected == 24){
							this.time_selected = 12
						}
					}
					formData.append("scheduledTime", this.time_selected);
				}
				postUrl = `/dashboard/notifications/scheduled`;
			}

			this.axios({
				method: "post",
				url: postUrl,
				data: formData,
				headers: {
					"Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
				},
			})
				.then((res) => {
					//If willProcessInBackground returns true, show modal with special message, else show success.
					if (res.data.data.willRunOn) {
						//Means its an scheduled notification

						this.showSuccess(
							"Success",
							`Notification is scheduled to be sent every ${
								+this.form.scheduledDays === 1
									? "day"
									: `${this.form.scheduledDays} days`
							}, starting on ${res.data.data.willRunOn}`
						);
					} else {
						//Its a one time notification
						res.data.data.willProcessInBackground
							? this.$bvModal.show("processInBackgroundMessage")
							: this.showSuccess("Success", `Notification sent succesfully`);
					}

					this.cleanAfterSubmit();
				})
				.catch((error) => {
					const message =
						error?.response?.data?.msg ?? "An unexpected error occurred";
					this.showError(message);
					console.error(error);
				})
				.finally(() => {
					this.$v.$reset();
					this.showOverlay = false;
					this.isFormApplied = false;
				});
		},

		cleanAfterSubmit() {
			this.form.title = null;
			this.form.activationDateFrom = null;
			this.form.activationDateTo = null;
			this.form.deActivationDateFrom = null;
			this.form.deActivationDateTo = null;
			this.form.state = null;
			this.form.status = null;
			this.form.master = null;
			this.form.retailer = null;
			this.form.scheduledDays = 1;
			this.time_selected = '01';
			this.form.nonUsageDaysStart = null;
			this.form.nonUsageDaysEnd = null;
			this.form.type = "app";
			this.form.message = null;
			if (this.form.image) this.$refs.image.reset();
			this.masters_id = [];
			this.isDisabled = true;
			this.form.image = null;
			this.notification_time = "oneTime";
			this.imagePreview = null;
		},
	},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.parent {
	width: 100%;
	height: 100%;
	background: #fff;
}

.button1 {
	color: white !important;
	background-color: #0094f8 !important;
}

.button1:hover {
	transition: opacity 0.3s ease-in-out;
	opacity: 0.8;
}

.button1:disabled {
	background-color: #495060 !important;
	color: white;
}

.btn {
	padding: 0.75rem 1rem;
}

.rmv-btn {
	padding: 0.2rem 0.6rem !important;
}

.time_picker {
	margin-top: 1rem;
	width: 50%;
}

.limiter {
	text-align: right;
    font-size: 0.875rem;
}
</style>
