import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/components/Login";
import Dashboard from "@/components/Dashboard";
import Notifications from "@/components/Notifications";
import SMScenter from "@/components/SMS";
import Users from "@/components/Users"
import store from "@/store/index";
import NotFound from "@/components/NotFound";
import SMSDashboard from '@/components/SMS-Dashboard';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/sms",
    name: "SMS",
    component: SMScenter,
    meta: { requiresAuth: true },
  },
  {
    path: "/sms-dashboard",
    name: "SMS-Dashboard",
    component: SMSDashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    meta: { requiresAuth: true },
  },
  /*{
    path: "/winbacks",
    name: "Winbacks",
    component: Winbacks,
    meta: { requiresAuth: true },
  },*/
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true
    },
  },
  {
    path: "*",
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {

  const isSignedIn = store.getters.loginState;

  // If current route is login, and we're logged in, go to dashboard
  if (isSignedIn && to.name === "login")
    return router.push({ name: "Dashboard" });

  // Protected routes
  if (!isSignedIn && to.matched.some((route) => route.meta.requiresAuth)) {
    if (from.name === "login") return { name: "login" };

    return router.push({ name: "login" });
  }

  next();

});

export default router
