<template>
	<div>
		<b-navbar toggleable="lg">
			<b-navbar-brand to="/dashboard">
				<img src="@/assets/img/logo.svg" />
			</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse" />

			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav class="ml-auto">
					<b-nav-item to="/dashboard" v-if="isSignedIn" :active="this.$route.name === 'dashboard'">
						Dashboard
					</b-nav-item>
					<b-nav-item to="/notifications" v-if="isSignedIn" :active="this.$route.name === 'notifications'">
						Notification center
					</b-nav-item>
					<b-nav-item
						to="/sms-dashboard" v-if="isSignedIn" :active="this.$route.name === 'sms-dashboard'">
						Internal SMS
					</b-nav-item>
					<b-nav-item to="/sms" v-if="isSignedIn" :active="this.$route.name === 'sms'">
						Send internal SMS
					</b-nav-item>
					<b-nav-item to="/login" v-if="!isSignedIn">
						Login
					</b-nav-item>
					<b-nav-item to="/users" v-if="isSignedIn && isAdmin">
						Users
					</b-nav-item>
					<b-nav-item @click.prevent="logout" v-if="isSignedIn">
						Logout
					</b-nav-item>
				</b-navbar-nav>
			</b-collapse>

		</b-navbar>
	</div>
</template>
<script>
export default {
	name: "Navbar",

	computed: {
		isSignedIn() {
			return this.$store.getters.loginState;
		},
		isAdmin() {
			return this.$store.getters.userRole == "admin";
		}

	},

	methods: {
		logout() {
			this.$store.dispatch("logout");
		},
	},
};
</script>

<style scoped lang="scss">

.navbar {
	padding-left: 4rem;
	padding-right: 4rem;
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;

	a.navbar-brand {
		margin-right: 2rem;

		img {
			width: 12rem;
			height: 30px;
		}
	}

	li.nav-item:not(:last-child) {
		margin-right: 10px;
	}

	a.nav-link {
		margin-left: 2rem;
		color: #495060;

		&:hover:not(.active) {
			transition: color 0.1s ease-in-out;
			color: #0094F8;
		}
	}
}

@media (max-width: 991px) {
	li.nav-item {
		margin-right: 0 !important;
	}
}

</style>